import {Box} from '@agorocarbon/box';
import {FormField} from '@agorocarbon/form-field';
import {TextInput} from '@agorocarbon/text-input';
import {Text} from '@agorocarbon/text';
import React, {useEffect, useState} from 'react';
import validation_tick from '../../assets/svg/skin_tick.svg';
import hide_password from '../../assets/svg/hide_password_icon.svg';
import show_password from '../../assets/svg/show_password_icon.svg';
import {t} from 'i18next';
import {Anchor} from '@agorocarbon/anchor';
import {Close} from "grommet-icons";
import {Form} from '@agorocarbon/form';

export const passwordDefaultValidator = (fieldValue: string) => {
  const result = getValidationResult(fieldValue);

  if (!result.hasMoreThan8Chars || fieldValue.length === 0) {
    return 'Please enter a valid password';
  }
  const optionalRequirements = [
    result.hasLowercase,
    result.haseUppercase,
    result.haseSpecialSymbol,
    result.hasNumber,
  ];
  if (
    optionalRequirements.filter((fulfillmentStatus) => fulfillmentStatus)
      .length < 3
  ) {
    return 'Please enter a valid password';
  }
  return undefined;
};

const formLabel = (label: string | React.ReactNode, error = false) => {
  if (typeof label === 'string') {
    return (
      <Box alignSelf="start">
        <Text
          margin={{left: '-8px'}}
          textAlign="start"
          weight="bold"
          color={error ? 'rgb(188, 0, 31)' : 'black'}
        >
          {label}
        </Text>
      </Box>
    );
  } else {
    return label;
  }
};

const getValidationResult = (fieldValue: string) => {
  return {
    hasLowercase: new RegExp(/[a-z]+/).test(fieldValue),
    haseUppercase: new RegExp(/[A-Z]+/).test(fieldValue),
    haseSpecialSymbol: new RegExp(/(?=.*[-+_!@#$%^&*.,?])+/).test(fieldValue),
    hasNumber: new RegExp(/[0-9]+/).test(fieldValue),
    hasMoreThan8Chars: !!fieldValue && fieldValue.length > 7,
  };
};

const AgPasswordFormField = ({field, formValue, error}: any) => {
  const [value, setValue] = useState<string>();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [label, setLabel] = useState<any>(field.label);
  const [hasError, setHasError] = useState<boolean>(false);

  console.log(error);
  useEffect(() => {
    if (field.label) {
      const hasError = !!error?.find((error: string) => error === field.name);
      const calculatedFormLabel = formLabel(field.label, hasError);
      setHasError(hasError);
      setLabel(calculatedFormLabel);
    }
  }, [error]);

  useEffect(() => {
    if (formValue && field) {
      setValue(formValue[field.name]);
    }
  }, [formValue, field.name]);
  const getRequirementFulfilledMark = (isFulfilled: boolean) => {
    return isFulfilled ? (
      <img
        src={validation_tick}
        alt={'AgoroLogo'}
        width="15px"
        height="15px"
        style={{
          marginLeft: '-24px',
          marginRight: '0.4rem',
          position: 'absolute',
          marginTop: '0.2rem',
        }}
      />
    ) : <Close
      size={'15px'}
      color={'rgb(19, 112, 188)'}
      style={{
        marginLeft: '-24px',
        marginRight: '0.4rem',
        position: 'absolute',
        marginTop: '0.2rem',
      }}/>;
  };

  const getColorValue = (isFulfilled: boolean) => {
    return isFulfilled ? '#2F7549' : '';
  };
  const getPasswordValidation = (fieldValue: string) => {
    const result = getValidationResult(fieldValue);
    return (
      <Box pad="medium" gap="small" style={{paddingLeft: '24px'}}>
        <Text weight="bold">{t('common.validation.passwordInfo.title')}</Text>
        <Text color={getColorValue(result.hasMoreThan8Chars)}>
          {getRequirementFulfilledMark(result.hasMoreThan8Chars)}{' '}
          {t('common.validation.passwordInfo.containAtLeast8')}
        </Text>
        <Text weight="bold">
          {' '}
          {t('common.validation.passwordInfo.containAtLeast3')}
        </Text>
        <Text color={getColorValue(result.hasLowercase)}>
          {getRequirementFulfilledMark(result.hasLowercase)}{' '}
          {t('common.validation.passwordInfo.lowerCaseLetters')}
        </Text>
        <Text color={getColorValue(result.haseUppercase)}>
          {getRequirementFulfilledMark(result.haseUppercase)}{' '}
          {t('common.validation.passwordInfo.upperCaseLetters')}
        </Text>
        <Text color={getColorValue(result.haseSpecialSymbol)}>
          {getRequirementFulfilledMark(result.haseSpecialSymbol)}{' '}
          {t('common.validation.passwordInfo.specialCharacters')}
        </Text>
        <Text color={getColorValue(result.hasNumber)}>
          {getRequirementFulfilledMark(result.hasNumber)}{' '}
          {t('common.validation.passwordInfo.numbers')}
        </Text>
      </Box>
    );
  };
  const getPasswordRevealIcon = (isShowPassword: boolean) => {
    return (
      <img
        src={isShowPassword ? hide_password : show_password}
        alt={isShowPassword ? 'Reveal Password' : 'Reveal Password'}
        width="15px"
        height="15px"
      />
    );
  };
  return (
    <React.Fragment key={field.fieldId}>
      <FormField
        key={field.fieldId}
        {...(field as any)}
        label={label}
        name={field.name}
        validate={field.validate || passwordDefaultValidator}>
        <Box
          direction="row"
          className={hasError ? 'ag-text-field-error' : 'ag-text-field'}>
          <TextInput
            plain
            type={isShowPassword ? 'text' : 'password'}
            size="16px"
            name={field.name}
            id={field.fieldId}
            placeholder={field.placeholder}
            style={{fontWeight: 'normal', padding: '8px', lineHeight: '24px'}}/>

          <Anchor
            style={{
              position: 'relative',
              marginRight: '5px',
              padding: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
            icon={getPasswordRevealIcon(isShowPassword)}
            onClick={() => setIsShowPassword(!isShowPassword)}/>
        </Box>
      </FormField>
      {field.shouldShowHintBox !== false
        ? getPasswordValidation(value || '')
        : null}
    </React.Fragment>
  );
};

export default AgPasswordFormField;
