import gql from 'graphql-tag';

export interface CreateAdvisorToMapWithGrower{
  addedByUser: string
  growerUserId: string
  email: string
  firstName: string
  lastName: string
  profession: string
}
export const CREATE_NEW_ADVISOR = gql`
  mutation createAdvisorToMapWithGrower(
    $CreateNewAdvisorInput: CreateNewAdvisorInput!
  ) {
    createAdvisorToMapWithGrower(CreateNewAdvisorInput: $CreateNewAdvisorInput)
  }
`;
