import mapboxgl, { Map } from 'mapbox-gl';

export type Coordinates = [number, number];

class CheckMarker extends mapboxgl.Marker {
  constructor() {
    const el = document.createElement('div');
    el.className = 'mapbox-check-marker';
    super(el);
  }

  show(coordinates: Coordinates, map: Map) {
    this.changeSize(false);
    this.setLngLat(coordinates).addTo(map);
  }

  hide() {
    this.remove();
  }

  changeSize(shouldIncrease: boolean) {
    this.getElement().classList.toggle('ready-to-close', shouldIncrease);
  }
}

export default new CheckMarker();
