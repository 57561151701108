import {FormFieldProps, FormField as GFormField } from 'grommet';
import React from 'react';
import { Box } from '@agorocarbon/box';
import { Text } from '@agorocarbon/text';
export interface FormProps extends FormFieldProps {
  children: React.ReactNode;
}

export function FormField({ children, ...props }: FormProps) {
  const formLabel = (label: string | React.ReactNode) => {
    if(typeof label === 'string'){
      return <Box alignSelf='start'>
        <Text margin={{ left: '-8px' }} textAlign='start' weight='bold'>{label}</Text>
      </Box>;
    } else {
      return label;
    }
  };
  if(props.label){
    props.label = formLabel(props.label)
  }
  return (
    <GFormField {...props}>
      {children}
    </GFormField>
  );
}

export default Text;
