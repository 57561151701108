import { useMemo } from 'react';
import { useQuery } from 'urql';
import { GET_FARM_QUERY } from '../graphql/queries/get.farm.query';
import { Farm } from '../graphql/types';
import { useParams } from 'react-router-dom';

export const useMemoFarm = (suspense = true): Farm => {
  const context = useMemo(() => ({ suspense }), [suspense]);
  const { farmId = '' } = useParams<{farmId: string}>();
  const [result] = useQuery({
    query: GET_FARM_QUERY,
    requestPolicy: 'network-only',
    variables: { farmId },
    pause: !farmId,
    context,
  });
  const { data } = result;
  return (
    data?.getFarmById?.farm || {
      farmId: '',
      farmName: '',
    }
  );
};
