// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import FieldDrawMode from './fieldDrawMode';
import FieldEditMode from './fieldEditMode';
import FieldInnerBorderMode from './fieldInnerBorderMode';
import PointDeletionMode from './pointDeletionMode';

export const MAP_MODES = {
  STATIC: 'static',
  DRAW_FIELD: 'draw_field',
  OUTER_BORDER: 'outer_border',
  INNER_BORDER: 'inner_border',
  POINT_DELETION: 'point_deletion',
};

export const customModes = {
  [MAP_MODES.STATIC]: StaticMode,
  [MAP_MODES.DRAW_FIELD]: FieldDrawMode,
  [MAP_MODES.OUTER_BORDER]: FieldEditMode,
  [MAP_MODES.INNER_BORDER]: FieldInnerBorderMode,
  [MAP_MODES.POINT_DELETION]: PointDeletionMode,
};
