import {Box} from "@agorocarbon/box";
import {Button} from "@agorocarbon/button";
import {MAP_MODES} from "./modes/modes";
import outerBorderIconInactive from "./assets/icons/outer-border.svg";
import outerBorderIconActive from "./assets/icons/outer-border-active.svg";
import {Text} from "@agorocarbon/text";
import innerBorderIconInactive from "./assets/icons/inner-border.svg";
import innerBorderIconActive from "./assets/icons/inner-border-active.svg";
import deletePointIconInactive from "./assets/icons/delete-point.svg";
import deletePointIconActive from "./assets/icons/delete-point-active.svg";
import trashBinIconActive from "./assets/icons/trash-bin-active.svg";
import trashBinIconInactive from "./assets/icons/trash-bin.svg";
import {Anchor} from "@agorocarbon/anchor";
import DrawControl from "react-mapbox-gl-draw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {useTranslation} from "react-i18next";
import {useState} from "react";

interface Params {
  drawControl: MapboxDraw;
  changeDrawingMode: (mode: string) => void;
  hasDrawnBoundaries: () => boolean;
  saveBoundariesHandler?: (features: any) => any;
  cancelHandler?: () => any;
  selectedFields?: any[];
}

const MapDrawingToolComponent = (props: Params) => {
  const {drawControl, changeDrawingMode, selectedFields, saveBoundariesHandler, cancelHandler} = props;
  const {t} = useTranslation();
  const [currentMode, setCurrentMode] = useState(MAP_MODES.STATIC);

  const saveBoundaries = () => {
    if (saveBoundariesHandler) {
      saveBoundariesHandler(drawControl.getAll());
    }
  };

  const buttonStyles: any = {margin: 'auto', padding: '5px', textAlign: "center", width: '80px'};

  const hasValidBoundaries = () => {
    let isValidGeometry = drawControl.getAll().features.length && true;
    for (const i of drawControl.getAll().features) {
      const geometry: any = i.geometry;
      if (!geometry.coordinates[0] || !geometry.coordinates[0][0]) {
        isValidGeometry = false;
      }

    }
    return isValidGeometry;
  }

  const buttonConfigurations = [
    {
      active: drawControl.getMode() === MAP_MODES.DRAW_FIELD || drawControl.getMode() === MAP_MODES.OUTER_BORDER,
      disabled: false,
      onClick: () => {
        changeDrawingMode(MAP_MODES.DRAW_FIELD);
        setCurrentMode(MAP_MODES.DRAW_FIELD);
      },
      style: buttonStyles,
      iconActive: outerBorderIconActive,
      iconInactive: outerBorderIconInactive,
      label: t(
        'fields.draw.fieldDrawing.fieldDrawingToolSection.toolBox.subTitle.outerBorder',
      ),
    },
    {
      active: drawControl.getMode() === MAP_MODES.INNER_BORDER,
      disabled: !selectedFields?.length,
      onClick: () => {
        changeDrawingMode(MAP_MODES.INNER_BORDER);
        setCurrentMode(MAP_MODES.INNER_BORDER);
      },
      style: buttonStyles,
      iconActive: innerBorderIconActive,
      iconInactive: innerBorderIconInactive,
      label: t(
        'fields.draw.fieldDrawing.fieldDrawingToolSection.toolBox.subTitle.innerBorder',
      ),
    },
    {
      active: drawControl.getMode() === MAP_MODES.POINT_DELETION,
      disabled: !selectedFields?.length,
      onClick: () => {
        changeDrawingMode(MAP_MODES.POINT_DELETION);
        setCurrentMode(MAP_MODES.POINT_DELETION);
      },
      iconActive: deletePointIconActive,
      iconInactive: deletePointIconInactive,
      label: t(
        'fields.draw.fieldDrawing.fieldDrawingToolSection.toolBox.subTitle.deletePoint',
      ),
      style: buttonStyles,
    },
    {
      active: false,
      disabled: false,
      onClick: () => {
        drawControl.deleteAll();
        changeDrawingMode(MAP_MODES.STATIC);
        setCurrentMode(MAP_MODES.STATIC);
      },
      style: buttonStyles,
      iconActive: trashBinIconActive,
      iconInactive: trashBinIconInactive,
      label: t(
        'fields.draw.fieldDrawing.fieldDrawingToolSection.toolBox.subTitle.restart',
      ),
    }
  ];

  return (
    <Box background={'#fff'} pad={'small'} direction="row" style={{
      position: 'absolute',
      bottom: '5rem',
      left: 0,
      right: 0,
      margin: 'auto',
      height: '115px',
      width: '615px'
    }}>
      <Box direction="row" border={"right"}>
        {buttonConfigurations.map(button =>
          (
            <Box key={button.label} pad={'small'}>
              <Button active={button.active}
                      disabled={button.disabled}
                      onClick={button.onClick}
                      style={button.style}>
                <img
                  src={!button.active ? button.iconInactive : button.iconActive}
                  alt={''}/>
                <Box><Text size={'small'}>{button.label}</Text></Box>
              </Button>
            </Box>
          )
        )}
      </Box>
      <Box pad={'small'}>
        <Button disabled={!hasValidBoundaries()} style={{margin: '.5rem'}} primary
                onClick={saveBoundaries} label={'Save Boundaries'}/>
        <Anchor style={{margin: '.2rem', textAlign: "center"}} onClick={cancelHandler} color={'link'} size={"large"}
                label={'Cancel'}/>
      </Box>
    </Box>
  )
};

export default MapDrawingToolComponent;
