import {FC, useEffect, useState} from "react";
import ReactMapboxGl from 'react-mapbox-gl';
import * as MapboxGl from 'mapbox-gl';
import {AgoroGeoJSONList} from "./GeoJSONList";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {Id} from '@turf/turf';
import {customModes, MAP_MODES} from "./modes/modes";
import MapDrawingToolComponent from "./MapDrawingTool.component";
import drawStyles from "./assets/styles/drawStyles";
import {FieldFeature} from "../../graphql/types";

const Map = ReactMapboxGl({
  accessToken: '' + process.env["REACT_APP_MAP_BOX_KEY"],
  logoPosition: 'top-left'
});

const MAP_STYLE = 'mapbox://styles/mapbox/satellite-streets-v11';

export const MapboxMap: FC<{
  position: {
    centerX: number;
    centerY: number;
    zoom: number;
    fieldId: string;
  };
  fields: FieldFeature[];
  zoomEffect?: boolean;
  drawEnabled?: boolean;
  drawSaveBoundariesHandler?: (features: any) => any;
  drawCancelHandler?: () => any;
  showAreaLabel?: boolean;
  currentFeatures?: { type: string, features: any[] };
}> = ({
        position,
        fields,
        zoomEffect,
        drawEnabled,
        drawSaveBoundariesHandler,
        drawCancelHandler,
        showAreaLabel = false,
        currentFeatures
      }) => {

  const [mapControl, setMapControl] = useState<MapboxGl.Map>();
  const [drawControl, setDrawControl] = useState<MapboxDraw>();
  const onLoaded = (map: MapboxGl.Map) => {
    setMapControl(map);
  };

  const [selectedFields, setSelectedFields] = useState([]);

  useEffect(() => {
    if (drawEnabled && mapControl && !drawControl) {
      const styles: any = drawStyles;
      const modes = {...MapboxDraw.modes, ...customModes};
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        modes,
        styles,
        defaultMode: MAP_MODES.STATIC
      });
      setDrawControl(draw);
      mapControl.addControl(draw);

      if (currentFeatures && currentFeatures.features?.length) {
        const featureCollection: any = currentFeatures;
        draw.add(featureCollection);
        const mapMode: any = MAP_MODES.OUTER_BORDER;
        const selectedIds: string[] = currentFeatures.features.map((i: any) => i.id);
        draw.changeMode(mapMode, {featureId: selectedIds[0]});
      }

      mapControl.on('draw.selectionchange', (ev: any) => {
        position.zoom = mapControl.getZoom();
        const center = mapControl.getCenter();
        position.centerX = center.lng;
        position.centerY = center.lat;
        setSelectedFields(ev.features);
      });
    }
  }, [mapControl]);

  const getSelected = (): any => {
    if (drawControl) {
      const selected = drawControl.getSelected();
      if (selected.type === 'FeatureCollection') {
        return selected.features[0];
      } else if (selected.type === 'Feature') {
        return selected;
      }
    }
    return;
  }

  const hasDrawnBoundaries = (): boolean => {
    const data = getSelected();
    return data?.features?.length;
  }

  const changeDrawingMode = (mode: string, featureId?: Id): void => {
    if (drawControl) {
      let feature: any = featureId;
      if (!featureId) {
        const selected = getSelected();
        feature = selected?.id;
      }
      const modeToChange: any = mode;
      if (feature) {
        drawControl.changeMode(modeToChange, {featureId: feature});
      } else {
        drawControl.changeMode(modeToChange);
      }
    }
  }

  return <Map
    style={MAP_STYLE}
    containerStyle={{
      height: '100%',
      width: '100%',
    }}
    // onClick={mapClickOnFeature}
    center={[position.centerX, position.centerY]}
    zoom={[position.zoom]}
    onStyleLoad={(map) => onLoaded(map)}
    animationOptions={{
      animate: zoomEffect,
    }}>
    <AgoroGeoJSONList
      fields={fields ?? []}
      fieldId={position.fieldId}
      showAreaLabel={showAreaLabel}/>
    {drawEnabled && drawControl ?
      <MapDrawingToolComponent changeDrawingMode={changeDrawingMode} drawControl={drawControl}
                               hasDrawnBoundaries={hasDrawnBoundaries}
                               selectedFields={selectedFields}
                               saveBoundariesHandler={drawSaveBoundariesHandler} cancelHandler={drawCancelHandler}
      />
      : <></>}
  </Map>
}
