import React from 'react';
import {Box, Layer, Spinner} from 'grommet';

const LoaderComponent = () => {
  return (
    <Layer>
      <Box style={{
        minWidth: '100vw',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Spinner size={"large"}/>
      </Box>
    </Layer>
  );
};

export default LoaderComponent;
