import { FC } from "react";
import {GeoJSONLayer} from "react-mapbox-gl";
import {FieldFeature} from "../../graphql/types";

export function getPolygonCenterCoordinateAsAPoint(polygon: any): {
  type: string;
  coordinates: number[];
} {
  let minx = 1000;
  let miny = 1000;
  let maxx = -1000;
  let maxy = -1000;
  if ('geometry' in polygon) polygon = polygon.geometry;
  if (polygon.type.toLowerCase() === 'polygon') {
    polygon = polygon.coordinates[0];

    for (let i = 0; i < polygon.length; i++) {
      const point = polygon[i];
      const x = point[0];
      const y = point[1];

      if (x < minx) minx = x;
      else if (x > maxx) maxx = x;
      if (y < miny) miny = y;
      else if (y > maxy) maxy = y;
    }
  } else {
    polygon = polygon.coordinates;
    polygon.forEach((poly: any[]) => {
      for (let i = 0; i < poly[0].length; i++) {
        const point = poly[0][i];
        const x = point[0];
        const y = point[1];

        if (x < minx) minx = x;
        else if (x > maxx) maxx = x;
        if (y < miny) miny = y;
        else if (y > maxy) maxy = y;
      }
    });
  }
  return {
    type: 'Point',
    coordinates: [minx + (maxx - minx) / 2, miny + (maxy - miny) / 2],
  };
}

const FieldColorTokens = {
  linePaintColor: '#00a1ff',
  lineWidth: 6,
  fillPaintColor: '#0C5692',
  fillWidth: 0.75,
  textColor: '#ffffff',
  fillOpacity: 0.7,

  nonFocusedFillOpacity: 0.75,
  nonFocusedLinePaintColor: '#00a1ff',
  nonFocusedLineWidth: 6,
  nonFocusedFillPaintColor: '#071019',
  nonFocusedFillWidth: 0.75,
  nonFocusedTextColor: '#ffffff',
  nonFocusedOpacity: 0.7,
};

interface GeoJSONSampleProps {
  field: FieldFeature;
  fieldId?: string;
  showAreaLabel?: boolean
}

export const AgoroGeoJSON: FC<GeoJSONSampleProps> = ({field, fieldId, showAreaLabel = false}: GeoJSONSampleProps) => {
  return (
    <>
      <GeoJSONLayer
        data={field}
        lineLayout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        linePaint={
          fieldId === field.properties?.fieldId
            ? {
              'line-color': FieldColorTokens.linePaintColor,
              'line-width': FieldColorTokens.lineWidth,
            }
            : {
              'line-color': FieldColorTokens.nonFocusedLinePaintColor,
              'line-width': FieldColorTokens.nonFocusedLineWidth,
            }
        }
        fillPaint={
          fieldId === field.properties?.fieldId
            ? {
              'fill-color': FieldColorTokens.fillPaintColor,
              'fill-opacity': FieldColorTokens.fillOpacity,
            }
            : {
              'fill-color': FieldColorTokens.nonFocusedFillPaintColor,
              'fill-opacity': FieldColorTokens.nonFocusedFillOpacity,
            }
        }
      />
      <GeoJSONLayer
        data={field}
        symbolPaint={{
          'text-color': FieldColorTokens.textColor,
        }}
        symbolLayout={{
          'text-allow-overlap': true,
          'text-field': showAreaLabel ? `${field.properties?.name ?? ''}\n${field.properties?.area ?? 0} acres` : field.properties?.name,
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.6],
          'text-anchor': 'top',
        }}
      />
    </>
  );
}
