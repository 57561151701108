export const QUERY_INVITE = `
  query invite($id: String!) {
    invite(inviteId: $id) {
      inviteId
      status
      advisorUserId
      growerUserId
      firstName
      lastName
      email
      phoneNumber
      isNewUser
      farm {
        farmId
        farmName
      }
    }
  }
`;
