import { createContext, useContext } from 'react';
import { IPropertyFieldForm } from '../components/PropertyRightComponents/PropertyRightInterfaces';
import { GeneralInformationType } from '../ESignatureFormTypes/GeneralInformationType';
import { PracticeSelectionStateType } from '../ESignatureFormTypes/PracticeFieldAssociationType';
import { FieldActions } from '../state/PracticeSelection';

export type ESignatureContext = {
  generalInfoState: GeneralInformationType;
  setGeneralInfoState: React.Dispatch<
    React.SetStateAction<GeneralInformationType>
  >;
  generalInfoStatedata: any;
  setGeneralInfoStatedata: React.Dispatch<React.SetStateAction<any>>;

  practiceSelectionState: PracticeSelectionStateType;
  dispatchPracticeSelectionState: React.Dispatch<FieldActions>;
  propertyRightsState: IPropertyFieldForm;
  setPropertyRightsState: React.Dispatch<
    React.SetStateAction<IPropertyFieldForm>
  >;
};

const ESignatureContext = createContext<ESignatureContext>(
  {} as ESignatureContext,
);

export default ESignatureContext;

export const useESignatureContext = (): ESignatureContext =>
  useContext(ESignatureContext);
