import {useAuth0} from "@auth0/auth0-react";
import LoaderComponent from "../../components/loader/Loader.component";

const LogoutPage = () => {
  const {logout} = useAuth0();
  logout({returnTo: window.location.origin});
  return <LoaderComponent />;
};

export default LogoutPage;
