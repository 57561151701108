import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom'
import { useClient } from 'urql';
import { useAuth0 } from '@auth0/auth0-react';
import advisorIcon from './assets/advisor.svg';

import { Box } from '@agorocarbon/box';
import { Form, FormValidationResults } from '@agorocarbon/form';
import { Loader } from '@agorocarbon/loader';
import { Heading } from '@agorocarbon/heading';
import {AgTextFormField as TextFormField} from "../../pages/add-growers/AgTextFormField";
import { Text } from '@agorocarbon/text';
import { Button } from '@agorocarbon/button';

import {
  CREATE_NEW_ADVISOR,
  CreateAdvisorToMapWithGrower
} from './queries/create.new.advisor.mutation';
import { showToast } from '../toast/toast';
// todo re-implement rudderstack
// import { RudderEventsTokens } from '../../../../../apps/c3/src/providers/RudderStackProvider/tokens/rudder.events.tokens';
// import { eventTrack } from '../../../../../apps/c3/src/providers/RudderStackProvider/rudder.analytics.service';

export function C3AdvisorOnboarding() {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { mutation } = useClient();
  const navigate = useNavigate();
  const {growerId} = useParams()

  const agoroUserId = user && user['https://api.pa.agoro.comuser_metadata'].agoroUserId;

  const fields = {
    email: {
      name: 'email',
      fieldId: 'email-input',
      type: 'text',
      label: t('advisor.addNewAdvisor.emailLabel'),
      required: true,
      placeholder: '',
      shortName: 'email address',
      regex: new RegExp(/^\S+@\S+\.\S+$/)
    },
    firstName: {
      name: 'firstName',
      fieldId: 'first-name-input',
      type: 'text',
      label: t('advisor.addNewAdvisor.firstNameLabel'),
      required: true,
      shortName: 'First Name',
      minLength: 2,
      maxLength: 30,
      placeholder: ''
    },
    lastName: {
      name: 'lastName',
      fieldId: 'last-name-input',
      type: 'text',
      label: t('advisor.addNewAdvisor.lastNameLabel'),
      required: true,
      minLength: 2,
      maxLength: 30,
      placeholder: ''
    },
    profession: {
      name: 'profession',
      fieldId: 'profession-input',
      type: 'text',
      label: t('advisor.addNewAdvisor.professionLabel'),
      required: false,
      placeholder: ''
    }
  };
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function advisorCreatedToast() {
    showToast({
      title: t('advisor.addNewAdvisor.toast.title'),
      message: t('advisor.addNewAdvisor.toast.subTitle'),
      type: 'success',
      toastId: 'advisor-created-toast-id'
    });
  }

  function creationErrorModal(message: string = t('common.unexpectedError')) {
    showToast({
      title: t('common.errorTitle'),
      message,
      type: 'error',
      toastId: 'C3AdvisorOnboarding-user-exists-id'
    });
  }

  // function sendRudderStackEvent(resultData: any) {
  //   // Analytics for Advisor Added
  //   return eventTrack(RudderEventsTokens.ADVISOR_ADDED, {
  //     extraContent: JSON.stringify(resultData)
  //   });
  // }

  function cancel() {
    navigate(`/growers/${growerId}/manage-advisor`);
  }

  function manageButton(form: FormValidationResults) {
      setFormValid(form.valid);
  }

  function submit(formValues: CreateAdvisorToMapWithGrower) {
    formValues.addedByUser = agoroUserId;
    if(!growerId) {
      creationErrorModal(`Couldn't get grower from url`);
      return
    }
    formValues.growerUserId = growerId;

    setIsLoading(true);
    return mutation(CREATE_NEW_ADVISOR, { CreateNewAdvisorInput: formValues }).toPromise()
      .then((res) => {
        const resultData = res?.data?.createAdvisorToMapWithGrower;
        if (resultData) {
          // sendRudderStackEvent(resultData);
          advisorCreatedToast();
          navigate(`/growers/${growerId}/manage-advisor`)
        } else {
          creationErrorModal(res?.error?.message?.split('[GraphQL] ')[1]);
        }
        setIsLoading(false);
      }).catch(() => {
        creationErrorModal();
        setIsLoading(false);
      });
  }

  return <>
    {isLoading && <Loader />}
    <Box width='100%' align='start'>
      <Box style={{width:'40vw', maxWidth: '30rem'}}  margin={{ left:'5rem', top: '2rem' }}>
        <Form
          validate={'change'}
          onValidate={manageButton}
          onSubmit={({ value }) => {
            return submit(value);
          }}
        >
          <img src={advisorIcon} width='87px' height='73px' />
          <Heading alignSelf='start' textAlign='start' margin={{ bottom: '0.5rem' }}
                   level='3'>{t('advisor.viewAdvisorList.addAdvisor')}</Heading>
          <Text color='rgb(80, 85, 101)'>{t('advisor.addNewAdvisor.subTitle')}</Text>

          <TextFormField {...fields.email} margin={{ bottom: '1em', top: '1.5rem' }} />
          <Box direction='row-responsive' justify='between' width='100%'>
            <TextFormField width='100%' {...fields.firstName} margin={{ bottom: '1em', right:'2em'}} />
            <TextFormField width='100%' {...fields.lastName} margin={{ bottom: '1em' }} />
          </Box>
          <TextFormField {...fields.profession} margin={{ bottom: '2em' }} />
          <Box width='20em'>
            <Button alignSelf='stretch' type='submit' primary disabled={!formValid}
                    label={t('advisor.addNewAdvisor.submitButtonLabel')} />
            <Button alignSelf='stretch' margin={{ top: '1rem' }}
                    label={t('common.cancel')} onClick={() => cancel()} />
          </Box>
        </Form>
      </Box>
    </Box>
  </>;
}

export default C3AdvisorOnboarding;
