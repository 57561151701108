import {useAuth0} from "@auth0/auth0-react";
const METADATA_KEY = `${process.env['REACT_APP_AUTH0_AUDIENCE']}user_metadata`;

export const useUserMetadata =() => {
  const result = useAuth0().user?.[METADATA_KEY]
  return (
    result || {
      agoroUserId: "",
      companyId: "",
      email: "",
      firstName: "",
      isNewUser: false,
      lastName: "",
      phoneNumber: "",
      userRole: "",
    }
  );
};
