import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useAuth0} from "@auth0/auth0-react";
// todo re-implement rudderstack
// import { eventTrack } from '../../../../../apps/c3/src/providers/RudderStackProvider/rudder.analytics.service';
// import { RudderEventsTokens } from '../../../../../apps/c3/src/providers/RudderStackProvider/tokens/rudder.events.tokens';
import { Box } from '@agorocarbon/box';
import { Text } from '@agorocarbon/text';
import { Button } from '@agorocarbon/button';
import { Checkbox } from '@agorocarbon/checkbox';
import { Heading } from '@agorocarbon/heading';
import { Form } from '@agorocarbon/form';
import { FormField } from '@agorocarbon/form-field';
import {AgTextFormField as TextFormField} from "../../pages/add-growers/AgTextFormField";
import { AgoToast } from '@agorocarbon/ag-toast';
import { Loader } from '@agorocarbon/loader';
import growerIcon from './assets/grower_setup_icon.svg';
import { showToast } from '../toast/toast';

// todo this should be elsewhere
import { useClient } from 'urql';
import { CREATE_GROWER_WITH_FARM } from './queries/create.new.grower.mutation';
import { GET_USER_EXIST_BY_EMAILID } from './queries/get.user.exist.by.email.query';


export function C3GrowerOnboarding() {
  //todo check why the toast need this import here to work correctly
  const {} = AgoToast;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutation, query } = useClient();
  const [isLoading, setLoading] = useState(false);

  function growerCreatedToast() {
    showToast({
      title: t('onboarding.growerWithFarmSetup.toasts.title.growerAdded'),
      message: t('onboarding.growerWithFarmSetup.toasts.content.growerAddedsubtitle'),
      type: 'success',
      toastId: 'C3GrowerOnboarding-grower-created-toast-id',
    });
  }

  function inviteSentToast(email: string) {
    showToast({
      title: t('onboarding.growerWithFarmSetup.toasts.title.inviteSent'),
      message: t('onboarding.growerWithFarmSetup.toasts.content.inviteSentBody', { email }),
      type: 'success',
      toastId: 'invite-sent-toast-id',
    });
  }

  function userExistsToast() {
    showToast({ // todo toast does not show
      message: t('grower.add.addGrowerPage.validations.growerAlreadyInvited'),
      type: 'error',
      toastId: 'user-exists-id',
    });
  }

  function creationErrorModal(message: string = t('common.unexpectedError')) {
    showToast({ // todo toast does not show
      title: t('common.errorTitle'),
      message,
      type: 'error',
      toastId: 'user-exists-id',
    });
  }

  // function ruddestackEvent(userMetaData: any, formValues:any, resultData:any) {
  //   // Analytics for Grower Invitation Sent by advisor
  //   return  eventTrack(
  //     RudderEventsTokens.GROWER_INVITATION_SENT_BY_ADVISOR,
  //     {
  //       description: `${formValues.growerEmail} grower invited by advisor ${userMetaData.agoroUserId}`,
  //       extraContent: JSON.stringify(resultData),
  //     },
  //   );
  // }


  const fields = {
    firstName: {
      name: 'firstName',
      fieldId: 'first-name-input',
      type: 'text',
      label: t('grower.add.addGrowerPage.inputField.label.growersFirstName'),
      required: true,
      shortName: 'First Name',
      minLength: 2,
      maxLength: 30,
      placeholder: t(
        'grower.add.addGrowerPage.inputField.placeholder.growersFirstName',
      ),
      value: '',
    },
    lastName: {
      name: 'lastName',
      fieldId: 'last-name-input',
      type: 'text',
      label: t('grower.add.addGrowerPage.inputField.label.growersLastName'),
      required: true,
      minLength: 2,
      maxLength: 30,
      placeholder: t(
        'grower.add.addGrowerPage.inputField.placeholder.growersLastName',
      ),
      value: '',
    },
    email: {
      name: 'growerEmail',
      fieldId: 'email-input',
      type: 'text',
      label: t('grower.add.addGrowerPage.inputField.label.growersEmailAddress'),
      required: true,
      placeholder: t(
        'grower.add.addGrowerPage.inputField.placeholder.growersEmailAddress',
      ),
      shortName: 'email address',
      regex: new RegExp(/^\S+@\S+\.\S+$/),
      value: '',
    },
    farmName: {
      name: 'farmName',
      fieldId: 'farm-name-input',
      type: 'text',
      label: t('common.label.businessName'),
      required: true,
      minLength: 2,
      maxLength: 30,
      placeholder: t('common.placeholder.businessName'),
      value: '',
    },
  };
  const checkUserExists = async (email: string) => {
    return query(GET_USER_EXIST_BY_EMAILID, { emailId: email }).toPromise().then((res) => {
      return res.data !== null;
    });
  };
  const submit = async (formValues: any) => {
    formValues.isLocal = false; // todo will we use this .env variable?
    const userExist = await checkUserExists(formValues.growerEmail);
    if (userExist) {
      setLoading(false);
      userExistsToast();
      return;
    }
    return mutation(CREATE_GROWER_WITH_FARM, formValues).toPromise().then((res) => {
      setLoading(false);
      if (res.data) {
        const resultData = res?.data?.createGrowerWithFarm;
        const farmId = resultData?.farm.farmId;
        if (farmId) {
          growerCreatedToast();
          if (formValues.isSendInvite) {
            inviteSentToast(formValues.growerEmail);
          }
          // if(formValues.isSendInvite && userRole === 'ADVISOR'){
          //   ruddestackEvent(user, formValues, resultData);
          // }
          navigate('/growers');
        } else {
          creationErrorModal(res.error?.message.split('[GraphQL] ')[1])
        }
      }else{
        creationErrorModal(res.error?.message.split('[GraphQL] ')[1])
      }
    }).catch(() => {
      showToast({
        message: t('common.unexpectedError'),
        type: 'error',
        toastId: 'unexpected-error-toast-id',
      });
    });
  };

  return <>
    {isLoading && <Loader />}
    <Box width='100%' align='center'>
      <Box width='25rem'>
        <Form
          validate={'change'}
          onSubmit={({ value }) => {
            setLoading(true);
            return submit(value);
          }}
        >
          <img src={growerIcon} width='100px' height='100px' />
          <Heading alignSelf='start' textAlign='start' margin={{top: '1rem'}}
                   level='4'>{t('grower.add.addGrowerPage.heading.addGrower')}</Heading>
          <TextFormField {...fields.firstName} margin={{ bottom: '0' }} />
          <TextFormField {...fields.lastName} margin={{ bottom: '0' }} />
          <TextFormField {...fields.email} margin={{ bottom: '1rem' }} />
          {/*todo turn into component*/}
          <Box>
            <FormField name='isSendInvite' htmlFor='textinput-id' contentProps={{ flex: true, alignContent: 'start' }}
                       margin={{ bottom: '0' }}>
              <Checkbox name='isSendInvite' id='textinput-id' fill label={<Box width='100%'><Text
                textAlign='start'>{t('grower.add.addGrowerPage.checkbox.label.inviteGrowerToSignUp')}</Text></Box>} />
            </FormField>
            <Text>{t('onboarding.growerWithFarmSetup.chkBoxtext')}</Text>
          </Box>
          <TextFormField {...fields.farmName} margin={{ bottom: '1rem' }} />
          <Box>
            <Button alignSelf='stretch' type='submit' primary
                    label={t('grower.add.addGrowerPage.buttons.addGrowerBtn')} />
          </Box>
        </Form>
      </Box>
    </Box>
  </>;
}
