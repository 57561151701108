import gql from 'graphql-tag';

export interface CreateUserMutationParams{
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  language: string;
  webId: string;
  userRole: string;
  companyId: string;
}

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $email: String!
    $language: String!
    $webId: String!
    $userRole: String!
    $companyId: String!
  ) {
    createUser(
      createUserInput: {
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        language: $language
        webId: $webId
        email: $email
        userRole: $userRole
        companyId: $companyId
      }
    ) {
      userId
      auth0Id
      firstName
      lastName
      phoneNumber
      language
      webId
      email
      userRole
      companyId
    }
  }
`;
