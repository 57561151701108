const loggerExchange = ({ client, forward }: any) => {
  client.subscribeToDebugTarget((event: any) => {
    if (event.type === 'fetchSuccess') {
      const queryObject = JSON.parse(event.data.fetchOptions.body);
      const resultKeys = Object.keys(event.data.value.data).join(', ');
      if (queryObject.query.indexOf('query') !== -1) {
        console.groupCollapsed(`%cQuery ${resultKeys} Success`, 'color: lime');
      }
      if (queryObject.query.indexOf('mutation') !== -1) {
        console.groupCollapsed(
          `%cQuery ${resultKeys} Success`,
          'color: dodgerBlue'
        );
      }
      console.log('Command', queryObject);
      console.log('Result', event.data.value.data);
      console.groupEnd();
      return;
    }
    if (event.type === 'fetchError') {
      const operationNames = event.operation.query.definitions
        .map((definition: any) => definition.name.value)
        .join(', ');
      const queryObject = JSON.parse(event.data.fetchOptions.body);
      console.groupCollapsed(`%cQuery ${operationNames} Error`, 'color: red');
      console.log('Command', queryObject);
      console.log('Variables', event.operation.variables);
      console.log('Error', event.data.value);
      console.groupEnd();
      return;
    }
    if (event.type === 'cacheHit') {
      const operationNames = event.operation.query.definitions
        .map((definition: any) => definition.name.value)
        .join(', ');
      console.groupCollapsed(`%cCacheHit ${operationNames}`, 'color: green');
      console.log('Variables', event.operation.variables);
      console.groupEnd();
      return;
    }
  });
  return forward;
};
export default loggerExchange;
