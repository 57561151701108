import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {QUERY_INVITE} from '../../graphql/queries/get.invite.by.id.query';
import GrowerInviteSignUp from './GrowerInviteSignUp';
import LoaderComponent from "../../components/loader/Loader.component";
import axios from "axios";
import {showToast} from "../../components/toast/toast";
import {t} from "i18next";
import {useTranslation} from "react-i18next";

const getInviteData = (inviteId?: string) => {
  const endpoint = process.env['REACT_APP_API_ENDPOINT'];
  const headers = {
    "content-type": "application/json",
  };

  // FIXME using axios to avoid auth error
  return axios({
    url: endpoint,
    method: 'post',
    headers: headers,
    data: {
      query: QUERY_INVITE,
      variables: {id: inviteId}
    }
  }).then((res) => {
    return res?.data?.data?.invite;
  });
}

const GrowerInvite = () => {
  const {inviteId} = useParams<{ inviteId: string }>();
  const [inviteData, setInviteData] = useState<any>(null);
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    getInviteData(inviteId).then(setInviteData);
  }, [inviteId])

  useEffect(() => {
    // user already have an account, redirecting to login page
    if (inviteData && !inviteData.isNewUser) {
      showToast({
        title: t('onboarding.growerInviteSignup.toast.inviteAccepted'),
        message: t('onboarding.growerInviteSignup.toast.inviteAcceptContent'),
        type: 'success',
        toastId: 'invite-accepted-toast'
      });
      navigate(`/`);
    }
  }, [inviteData, navigate, t]);

  if (inviteData && inviteData.isNewUser) {
    return (
      <GrowerInviteSignUp invite={inviteData}/>
    );
  } else {
    return <LoaderComponent/>;
  }
};

export default GrowerInvite;
