import {useNavigate, useParams} from "react-router-dom";
import {Box} from "@agorocarbon/box";
import {Anchor, Spinner} from "grommet";
import React, {useEffect, useState} from "react";
import {PageTitle} from "../../components/page-title/PageTitle.component";
import {FieldsViewList} from "../../components/fields/FieldsViewList.component";
import {getLatitudeValue, getLongitudeValue} from "../../utils/utilities";
import {MapboxMap} from "../../components/mapbox/MapboxMap.component";
import {useTranslation} from "react-i18next";
import useListFields from "../../hooks/UseListFieldsFromFarm.hook";
import useGetContractForFarm from "../../hooks/UseGetContractForFarm.hook";
import {useMutation} from "urql";
import {Button} from '@agorocarbon/button';
import BreadCrumbComponent from "../../components/breadcrumb/BreadCrumb.component";
import {useMemoFarm} from "../../hooks/useMemoFarm";
import {useMemoGrower} from "../../hooks/useMemoGrower";
import {useUserMetadata} from "../../hooks/useUserMetadata";
import { showToast } from "../../components/toast/toast";
import {RemoveFieldFeature} from "../../graphql/types";
import {REMOVE_FIELDS} from "../../graphql/mutations/remove.fields.mutation";

const DeleteFieldsPage = () => {
  const {farmId, growerId} = useParams<{ farmId: string, growerId: string }>();
  const {fields, isFetching} = useListFields(farmId ?? '');
  const {contractResponse} = useGetContractForFarm(farmId ?? '');
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {firstName, lastName, email, userId, userRole} = useMemoGrower();
  const {farmName} = useMemoFarm();
  const userMetadata = useUserMetadata();

  const [, removeFieldOfFarm] = useMutation<{
    removeFields: RemoveFieldFeature;
  }>(REMOVE_FIELDS);

  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  //todo fix this repeated code
  useEffect(() => {
    if (fields && fields.length) {
      setPosition({
        centerX: getLatitudeValue(fields[0].geometry),
        centerY: getLongitudeValue(fields[0].geometry),
        zoom: 14,
        fieldId: '',
      });
    }
  }, [fields]);

  const [position, setPosition] = useState({
    centerY: 38.8951,
    centerX: -77.0364,
    zoom: 2,
    fieldId: '',
  });

  function updateHover(x: number, y: number, z: number, fieldId: string) {
    if (x && y && z) {
      setPosition({
        centerX: x,
        centerY: y,
        zoom: z,
        fieldId: fieldId,
      });
    }
  }

  const deleteFields = async () => {
    const ids = selectedFields;
    try {
      const removeFieldResult = await removeFieldOfFarm({ ids, farmId });
      if (
        removeFieldResult?.data &&
        removeFieldResult.data.removeFields?.deletedCount > 0
      ) {
        showToast({title: 'Fields successfully deleted', message: 'Your fields have been deleted.', type: 'success', toastId: 'toast-fields-removed-success'});
        navigate(`/growers/${growerId}/farms/${farmId}/fields/view`);
      } else {
        showToast({title: 'Error', message: removeFieldResult.error?.message.split('[GraphQL] ')[1], type: 'error', toastId: 'toast-fields-removed-error' })
      }
    } catch (err) {
      showToast({title: 'Error', message: 'Unexpected error', type: 'error', toastId: 'toast-fields-removed-error' })
    }
  };

  const breadcrumbPaths = [];
  if (userMetadata.userRole === 'ADVISOR') {
    breadcrumbPaths.push({label: `${firstName} ${lastName}`, route: '/growers'});
  }
  breadcrumbPaths.push({label: `${farmName}`, route: `/growers/${growerId}/farms`});
  breadcrumbPaths.push({label: t('fields.list.fieldList.breadCrumb.fieldList'), route: `/growers/${growerId}/farms/${farmId}/fields/view`});
  breadcrumbPaths.push({label: 'Delete Fields', route: '#'});

  return (
    isFetching ? <Box
      margin={'2vh'}
      alignSelf={'center'}
      align={'center'}>
      <Spinner size={'large'} alignSelf={'center'}/>
    </Box> : <Box
      direction={'row'}
      height={'100%'}
      justify={'between'}>
      <Box
        margin={{left: '4vw', vertical: '4vh'}}
        direction={'column'}
        width={{min: '40%'}}>

        {firstName && farmName && <BreadCrumbComponent paths={breadcrumbPaths} />}

        <Box style={{marginTop: '1rem'}}>
        <PageTitle
          title={'Delete Fields'}
          button={false}/>
        </Box>

       <p style={{color: 'rgb(35, 39, 53)', lineHeight: '24px', fontSize: '16px', margin: 0}}>
         Please select the field(s) that you wish to delete.
       </p>

        <Anchor
          style={{textDecorationLine: 'underline'}}
          margin={{vertical: 'medium'}}
          onClick={() => selectedFields.length === fields.length ? setSelectedFields([]) : setSelectedFields(fields.map((field) => field.properties.fieldId))}
          alignSelf={'start'}
          label={selectedFields.length === fields.length ?
            t('eContract.enrollToProgramFieldList.deSelectAllField') :
            t('eContract.enrollToProgramFieldList.selectAllField')}
          size={'xlarge'}
          color={'rgb(19, 112, 188)'}/>

        <FieldsViewList
          selectable={true}
          showPercentage={false}
          fields={fields}
          selectedFields={selectedFields}
          onItemClick={(field) => {
            const fieldIndex = selectedFields.findIndex((fieldSearch) => fieldSearch === field.properties.fieldId);
            if (fieldIndex !== -1) {
              selectedFields.splice(fieldIndex, 1)
            } else {
              selectedFields.push(field.properties.fieldId);
            }
            updateHover(
              getLatitudeValue(field.geometry),
              getLongitudeValue(field.geometry),
              14,
              field.properties.fieldId,
            )
          }}/>

        <Button
          primary
          disabled={selectedFields.length <= 0}
          style={{
            height: '40px',
            width: 'calc(80% + 41px + 1rem)',
            background: 'rgb(252,225,92)'
          }}
          onClick={deleteFields}
          label={'Delete'}/>

        <Anchor
          style={{textDecorationLine: 'underline'}}
          margin={{top: 'small', right: 'calc(20% - 41px - 1rem)'}}
          onClick={() => navigate(`/growers/${growerId}/farms/${farmId}/fields/view`)}
          alignSelf={'center'}
          label={t('common.cancel')}
          size={'xlarge'}
          color={'rgb(19, 112, 188)'}/>
      </Box>

      <MapboxMap
        position={position}
        fields={fields}
        zoomEffect={false}/>
    </Box>
  );
}

export default DeleteFieldsPage;
