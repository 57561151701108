/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MAP_MODES } from './modes';
import BaseFieldDrawMode from './baseFieldDrawMode';

export const DrawField = { ...BaseFieldDrawMode };

DrawField.clickOnVertex = function (state: any) {
  if (state.polygon.coordinates[0].length < 4) {
    return;
  }

  return this.changeMode(MAP_MODES.OUTER_BORDER, {
    featureId: state.polygon.id,
  });
};

export default DrawField;
