import {FormField} from '@agorocarbon/form-field';
import {RadioButtonGroup as AgRadioButtonGroup, FormFieldExtendedProps} from 'grommet';
import {Box} from '@agorocarbon/box';
import {Text} from '@agorocarbon/text';
import * as React from 'react';

export interface RadioOptionsProps {
  disabled?: boolean;
  id?: string;
  title: string;
  default?: boolean;
  description?: string;
  value: string | number | boolean;
  label?: React.ReactNode;
  titleSize?: string;
  descriptionSize?: string;
}

interface AgRadioButtonGroupProps extends FormFieldExtendedProps {
  radioOptions: RadioOptionsProps[]
  name: string;
  label?: string;
  errorMessage?: string;
  value?: string;
}


function optionLabel(title: string, subtitle = '', titleSize: string | undefined, descriptionSize: string | undefined) {
  return <Box>
    <Text weight='bold' size={titleSize ? titleSize : 'medium'} margin={{bottom: '0.25rem'}}>{title}</Text>
    {subtitle && <Text size={descriptionSize ? descriptionSize : '16px'} color='rgb(80, 85, 101)'>{subtitle}</Text>}
  </Box>;
}

const formError = (placeholder: string | React.ReactNode) => {
  if (typeof placeholder === 'string') {
    return <Box alignSelf='start'>
      <Text textAlign='start' weight="lighter" color="rgb(188, 0, 31)">{placeholder}</Text>
    </Box>;
  } else {
    return placeholder;
  }
};


export function RadioButtonFormGroup(props: AgRadioButtonGroupProps) {
  const {radioOptions: options, name} = props;
  let defaultValue;
  let errorMessage;
  if (props.errorMessage) {
    errorMessage = formError(props.errorMessage);
  }

  for (const option of options) {
    option.label = optionLabel(option.title, option.description, option.titleSize, option.descriptionSize);
    option.id = option.id || 'radio-option-' + option.value;
    if (option.default) {
      defaultValue = option.value;
    }
  }
  if (props.value) {
    defaultValue = props.value;
  }
  return (
    <FormField {...props}>
      {errorMessage}
      <AgRadioButtonGroup gap={'1rem'} name={name} options={options} value={defaultValue}/>
    </FormField>
  );
}

export default RadioButtonFormGroup;
