import {useClient} from 'urql';
import {useEffect, useState} from "react";
import {GET_FIELDS_WITH_ELIGIBILITY_QUERY} from "../graphql/queries/get.fields.with.eligibility.query";

const listFieldsByFarm = async (executeQuery: any, farmId: string) => {
  const query: any = GET_FIELDS_WITH_ELIGIBILITY_QUERY;
  const params: any = {
    farmId
  };

  const response = await executeQuery(query, {...params}, {requestPolicy: 'network-only'}).toPromise();
  return {data: response.data, isFetching: false, error: null};
};

interface Request {
  fields: any[];
  eligibility: any[];
  isFetching: boolean;
  error: any;
}

export const useListFields = (farmId: string) => {

  const {query} = useClient();

  const [response, setResponse] = useState<Request>({fields: [], eligibility: [], isFetching: true, error: null});

  useEffect(() => {
    listFieldsByFarm(query, farmId).then(res => {
      const { data , isFetching } = res;

      const eligibility = data?.getFieldsWithLastEligibilityTest?.eligibility ?? [];
      const fields = data?.getFieldsWithLastEligibilityTest?.fields ?? [];
      fields.sort((fieldLeft: any, fieldRight: any) => fieldLeft.properties.name.toLowerCase().localeCompare(fieldRight.properties.name.toLowerCase()));

      setResponse({
        fields,
        eligibility,
        isFetching,
        error: res.error
      });
    }).catch((err) => {
      setResponse({fields: [], eligibility: [], isFetching: false, error: err});
    })
  }, [query, farmId]);

  return response;
};

export default useListFields;
