import {Box} from '@agorocarbon/box';
import {Button} from '@agorocarbon/button';
import {Heading} from '@agorocarbon/heading';
import {Text} from '@agorocarbon/text';
import {Form} from 'grommet';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import onboardingIcon from '../../assets/svg/onboarding_icon.svg';
import {
  AcceptInviteMutationParams,
  ACCEPT_INVITE_MUTATION,
} from '../../graphql/mutations/invite.accept.grower.mutation';
import {AgTextFormField} from '../add-growers/AgTextFormField';
import AgPasswordFormField from './AgPasswordFormField';
import AgSelectFormField from './AgSelectFormField';
import axios from "axios";
import {showToast} from "../../components/toast/toast";
import {useTranslation} from "react-i18next";

const GrowerInviteSignUp = ({invite}: any) => {
  const [formValue, setFormValue] = useState<any>(null);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const getDbInputFromValue = (formValue: any): AcceptInviteMutationParams => {
    return {
      inviteId: invite.inviteId,
      userId: invite.growerUserId,
      userRole: 'GROWER',
      email: formValue.email,
      password: formValue.password,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phoneNumber: formValue.phoneNumber,
      language: formValue.language.value,
    };
  };
  const saveUserValues = (value: any) => {
    const userDbInput = getDbInputFromValue(value);

    const endpoint = process.env['REACT_APP_API_ENDPOINT'];
    const headers = {
      "content-type": "application/json",
    };
    axios({
      url: endpoint,
      method: 'post',
      headers: headers,
      data: {
        query: ACCEPT_INVITE_MUTATION,
        variables: userDbInput
      }
    })
      .then((result: any) => {
        // account was created successfully, redirecting user to login page
        showToast({
          title: t('onboarding.growerInviteSignup.toast.inviteAccepted'),
          message: t('onboarding.growerInviteSignup.toast.inviteAcceptContent'),
          type: 'success',
          toastId: 'invite-accepted-toast'
        });
        navigate(`/`);
      })
      .catch((e) => {
        showToast({
          message: t('common.unexpectedError'),
          type: 'error',
          toastId: 'unexpected-error-toast-id',
        });
      });
  };
  const fields = [
    {
      name: 'firstName',
      fieldId: 'first-name-input',
      type: 'text',
      label: t('common.label.firstName'),
      required: true,
      minLength: 2,
      maxLength: 30,
      placeholder: t('common.placeholder.firstName'),
      value: invite.firstName,
      variant: '',
    },
    {
      name: 'lastName',
      fieldId: 'last-name-input',
      type: 'text',
      label: t('common.label.lastName'),
      required: true,
      minLength: 2,
      maxLength: 30,
      placeholder: t('common.placeholder.lastName'),
      value: invite.lastName,
      variant: '',
    },
    {
      name: 'phoneNumber',
      fieldId: 'phone-number-input',
      type: 'text',
      label: t('common.label.phoneNumber'),
      required: false,
      minLength: 6,
      maxLength: 30,
      regex: new RegExp(/^[()+0-9\b-]+$/),
      placeholder: t('common.placeholder.phoneNumber'),
      value: invite.phoneNumber,
      variant: '',
    },
    {
      name: 'language',
      fieldId: 'language-input',
      type: 'dropdown',
      label: t('common.label.language'),
      required: true,
      options: [
        {label: 'English', value: 'en-US'},
        {label: 'Espana', value: 'es-US'},
      ],
      value: {label: 'English', value: 'en-US'},
      variant: '',
    },
    {
      name: 'email',
      fieldId: 'email-input',
      type: 'text',
      label: t('common.label.email'),
      required: true,
      regex: new RegExp(/^\S+@\S+\.\S+$/),
      disabled: true,
      placeholder: t('common.placeholder.email'),
      value: invite.email,
      variant: '',
    },
    {
      name: 'password',
      fieldId: 'password-input',
      type: 'password',
      label: t('common.label.password'),
      placeholder: t('common.placeholder.password'),
      value: '',
      variant: '',
    },
  ];

  // TODO: This function can be refactored out into a factory pattern to generate the component from a generic place
  const getFormFieldComponent = (field: any) => {
    if (field.type === 'text') {
      return (
        <AgTextFormField
          key={field.fieldId}
          {...(field as any)}
          margin={{bottom: '0'}}/>
      );
    }
    if (field.type === 'dropdown') {
      return (
        <AgSelectFormField
          key={field.fieldId}
          formValue={formValue}
          field={field}/>
      );
    }
    if (field.type === 'password') {
      return (
        <AgPasswordFormField
          key={field.fieldId}
          formValue={formValue}
          field={field}/>
      );
    }
    return <div key={field.fieldId}>{field.type} input placeholder</div>;
  };

  //TODO: This form currently uses a mixed bag of controlled and uncontrolled components.  The controlled components require formValue to tell it what values to display, whereas uncontrolled components does not.  The reason each component needs the controlled aspect is in the components themselves, they should be refactored out in the future so this form only uses 1 method.
  const getForm = (formFields: Array<any>) => {
    return (
      <Form
        style={{width: '100%'}}
        onChange={(change: any) => {
          setFormValue({...change} as any);
        }}
        validate="blur"
        onSubmit={({value}) => {
          saveUserValues(value);
        }}
      >
        {formFields.map((field) => {
          return getFormFieldComponent(field);
        })}
        <Box pad={{bottom: 'large'}}>
          <Button
            alignSelf="stretch"
            type="submit"
            primary
            label={t('onboarding.growerInviteSignup.buttons.createAccount')}
          />
        </Box>
      </Form>
    );
  };

  return (
    <Box direction="column" align="center">
      <Box direction="column" align="start" width="large">
        <Text margin={{top: 'medium'}} alignSelf="start" textAlign="start">
          <img src={onboardingIcon} alt={'Onboarding Icon'}/>
        </Text>
        <Heading alignSelf="start" textAlign="start" level="4" fill={true}>
          {t(
            'onboarding.onboardingPersonalInformation.heading.createYourAccount'
          )}
        </Heading>
        <Text size="large">
          {t('onboarding.growerInviteSignup.subHeading.invitedToJoin')}
          <b>&nbsp; {invite.farm.farmName || ''}</b>
        </Text>
        <Text weight="bold">
          {t(
            'onboarding.onboardingPersonalInformation.subHeading.enterPersonalInfo'
          )}
        </Text>
        {getForm(fields)}
      </Box>
    </Box>
  );
};
export default GrowerInviteSignUp;
