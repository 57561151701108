import gql from 'graphql-tag';

export const GET_GROWER_BY_ID_QUERY = gql`
  query getGrowerById($userId: String!) {
    user(userId: $userId) {
      phoneNumber
      userId
      firstName
      lastName
      email
      userRole
      language
      webId
    }
  }
`;
