import * as ReactDOM from 'react-dom';

import { HelmetProvider } from 'react-helmet-async';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { defaultTheme, ThemeProvider } from '@agorocarbon/theme-provider';
import { deepMerge } from 'grommet/utils';
import Auth0ProviderWithHistory from './providers/Auth0ProviderWithHistory/Auth0ProviderWithHistory';
import React from 'react';
import App from './App';
import { FormDown, FormUp } from 'grommet-icons';
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: String(process.env['REACT_APP_LAUNCH_DARKLY_USER_ID']),
  });

  const theme = deepMerge(defaultTheme, {
    global: {
      colors: {
        'selected-background': 'rgb(243, 246, 249)',
        'selected-text': 'rgb(12, 85, 156)',
        'background-contrast': {
          dark: '#282828',
          light: 'rgb(243, 246, 249)',
        },
        focus: 'rgb(238, 254, 241)',
        brand: { dark: 'ffe137', light: '#FFE137' },
      },
      hover: {
        background: {
          color: 'light-green',
        },
        color: {
          dark: 'lightgrey',
          light: 'brand',
        },
      },
      focus: {
        border: {
          color: 'none',
        },
      },
    },
    error: {
      border: {
        color: 'red',
      },
    },
    formField: {
      border: false,
    },
    anchor: {
      fontWeight: '300',
      color: 'rgb(19, 112, 188)',
      textDecoration: 'underline',
    },
    radioButton: {
      border: {
        color: 'rgb(19, 112, 188)',
        width: '1px',
      },
      check: {
        color: 'rgb(0, 79, 41)',
      },
      color: 'rgb(0, 79, 41)',
      extend: {
        'align-self': 'flex-start',
        height: '18px',
        width: '18px',
      },
    },
    select: {
      container: {
        extend: `
        & button {
          &:hover {
            background-color: rgb(243, 246, 249);
            color:rgb(12, 85, 156);
          }
        }`,
      },
      icons: {
        color: '#326FAE',
        margin: '10px 10px',
        down: <FormDown size="20" />,
        up: <FormUp size="20" />,
      },
      control: {
        open: `
          border: 1px solid rgb(0, 79, 41);
          background-color: rgb(238, 254, 241);
          &:hover {
            background-color: rgb(243, 246, 249);
          }
        `,
        extend: `border: 1px solid #326FAE; 
          border-radius: 1px;
          background: #fff;
          &:focus-within {
            border: 1px solid rgb(0, 79, 41);
            background-color: rgb(238, 254, 241);
          }
          `,
      },
    },
    tip: {
      content: {
        background: 'black',
      },
      drop: {
        align: {
          left: 'left',
        },
      },
    },
    menu: {
      icons: {
        color: 'rgb(25, 20, 8)',
      },
    },
    checkBox: {
      toggle: {
        background: 'lightgrey',
        radius: '18px',
        size: '36px',
        knob: {},
        extend: `
        border: 0
          `,
      },
      extend: `
      & input:checked + div{
        background-color: rgb(238,254,241);
      }
      `,
      check: {
        thickness: '2px',
      },
    },
  });
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme} themeMode="light">
        <LDProvider>
          <Auth0ProviderWithHistory>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </Auth0ProviderWithHistory>
        </LDProvider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();
