import {useClient} from 'urql';
import {useEffect, useState} from "react";
import {FIND_CONTRACT_FOR_FARM} from "../graphql/queries/get.contract.by.farm.query";

const getContractForFarm = async (executeQuery: any, farmId: string) => {
  const query: any = FIND_CONTRACT_FOR_FARM;
  const params: any = {
    farmId,
    requestPolicy: 'network-only'
  };

  const response = await executeQuery(query, {...params}).toPromise();
  return {data: response.data, isFetching: false, error: null};
};

interface Request {
  contractResponse: any,
  contractProgress: boolean,
  error: any
}

const useGetContractForFarm = (farmId: string) => {

  const {query} = useClient();
  const [response, setResponse] = useState<Request>({contractResponse: null, contractProgress: true, error: null});

  useEffect(() => {
    if (farmId === '') {
      return;
    }

    getContractForFarm(query, farmId).then(res => {
      const contractResponse = res.data?.findContractForFarm;
      setResponse({
        contractResponse,
        contractProgress: res.isFetching,
        error: null
      });
    }).catch((err) => {
      setResponse({contractResponse: null, contractProgress: true, error: err});
    })
  }, [query, farmId]);

  return response;
};

export default useGetContractForFarm;
