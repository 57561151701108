import {Button, Heading, Text} from "grommet";
import {useTranslation} from "react-i18next";
import {Box} from '@agorocarbon/box';
import {useNavigate, useParams} from "react-router-dom";
import useListFields from "../../hooks/UseListFieldsFromFarm.hook";

const NoContractsPage = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {growerId, farmId} = useParams<{growerId: string, farmId: string}>();

  const {fields, isFetching} = useListFields(farmId ?? '');

  return (
    <Box justify={"center"} align={"start"} alignContent={"center"} margin={'4%'}>
      <Heading size={"small"}>
        {t('eContract.noSignedContract.title')}
      </Heading>

      {fields?.length === 0 && !isFetching && (
        <Box
          pad={{top: '2.5em'}}
          direction={'column'}
          width={'60%'}
          justify={'start'}>
          <Text size={'large'} data-cy={'nocontract_subheading'}>
            {t('eContract.noSignedContract.noFieldsEligibility')}
          </Text>

          <Button
            margin={{top: '2.5em'}}
            style={{background: 'white', border: '0.125rem solid rgb(252,225,92)', width: '50%'}}
            label={t('eContract.noSignedContract.addFieldInfo')}
            onClick={() => navigate(`/growers/${growerId}/farms/${farmId}/fields/upload`)}/>
        </Box>
      )}
    </Box>
  )
};

export default NoContractsPage;
