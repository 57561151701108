// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { geojsonTypes } from '@mapbox/mapbox-gl-draw/src/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';

const EditField = { ...DirectSelect };

interface Opts {
  featureId: number;
}

EditField.onSuperSetup = DirectSelect.onSetup;
EditField.onSetup = function (opts: Opts) {
  const state = DirectSelect.onSetup.call(this, opts);

  // Select first vertex by default in order to enable edit mode
  if (state.feature.type === geojsonTypes.POLYGON) {
    state.selectedCoordPaths = ['0.0'];
  }
  if (state.feature.type === geojsonTypes.MULTI_POLYGON) {
    state.selectedCoordPaths = state.feature.features.map(
      (_: any, index: number) => `${index}.0.0`,
    );
  }

  const selectedCoordinates = this.pathsToCoordinates(
    state.featureId,
    state.selectedCoordPaths,
  );
  this.setSelectedCoordinates(selectedCoordinates);

  return state;
};

// Prevent anything else from being selected besides vertices
EditField.onTap = function () {
  return undefined;
};
EditField.onClick = function (state: any) {
  this.stopDragging(state);
};

export default EditField;
