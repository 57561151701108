import React, { useEffect } from 'react';

import {
  rudderInitialize,
  eventIdentify,
  eventPage,
  eventTrack,
  RudderEventPropertiesType,
} from './rudder.analytics.service';

type RudderStackContextProps = {
  eventIdentify: any;
  eventPage: any;
  eventTrack(
    eventName: string,
    properties: RudderEventPropertiesType,
  ): Promise<string>;
};

type RudderStackProviderProps = {
  children: React.ReactNode;
};
const RudderStackContext = React.createContext<RudderStackContextProps>({
  eventIdentify,
  eventPage,
  eventTrack,
});

function RudderStackProvider({
  children,
}: RudderStackProviderProps): JSX.Element {
  useEffect(() => {
    rudderInitialize();
  }, []);
  return (
    <RudderStackContext.Provider
      value={{ eventIdentify, eventPage, eventTrack }}
    >
      {children}
    </RudderStackContext.Provider>
  );
}

export { RudderStackProvider, RudderStackContext };
