/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { cloneDeep, forIn, isObject } from 'lodash';
import {
  EContracting,
  EContractingFieldInput,
  EContractingFieldPractice,
  EContractingFieldPracticeInput,
  EContractingFields,
  FieldFeature,
  Maybe,
} from '../../graphql/types';
//todo fix this ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PracticeInfo from '../ESignatureFormTypes/PractiseInfo.json';
// import {
//   ILandOwnerDetails,
//   ILeaseDetails,
//   IPropertyFieldForm,
//   isAllFieldsHaveStatus,
//   isAnyFieldsInLease,
//   ISelectField,
// } from '../components/PropertyRightComponents';
import {
  EndOfGrowingSeasonType,
  FieldSelection,
  PaymentOrPractiseAdoptionType,
  PracticeFieldAssociationType,
  PracticeSelectionStateType,
  SummaryParentValidationState,
} from '../ESignatureFormTypes/PracticeFieldAssociationType';
import { PracticeInfoType } from '../ESignatureFormTypes/PracticeInfoType';
import { returnTotalArea } from './PracticeSelection';
import { FieldOwnerShipFlags } from './PropertyRights';
import {getLatitudeValue, getLongitudeValue} from "../../utils/utilities";
import {
  ILandOwnerDetails,
  ILeaseDetails,
  IPropertyFieldForm, ISelectField
} from '../components/PropertyRightComponents/PropertyRightInterfaces';
import {isAllFieldsHaveStatus, isAnyFieldsInLease} from "../components/PropertyRightComponents/commonFunctions";

export function getUniqueFieldsFromContract(
  contractData: EContracting,
): FieldFeature[] {
  if (contractData && contractData.enrolledFields && contractData.fields) {
    return contractData.enrolledFields.filter((enrolledField) => {
      if (contractData.fields.fieldPractices) {
        let fieldFound = false;
        for (
          let index = 0;
          index < contractData.fields.fieldPractices.length;
          index++
        ) {
          const element = contractData.fields.fieldPractices[index];
          fieldFound = element.fields.some((field) => {
            return field.fieldId === enrolledField.properties.fieldId;
          });
          if (fieldFound) {
            break;
          }
        }
        return fieldFound;
      } else {
        return false;
      }
    });
  } else {
    return [];
  }
}

export function getFieldsFromPracticeSelection(
  eligibleFields: FieldFeature[] | undefined,
  coverCropping: PracticeFieldAssociationType,
  improvedTillage: PracticeFieldAssociationType,
  nitrogenOptimization: PracticeFieldAssociationType,
  zeroTillage: PracticeFieldAssociationType,
  pastureManagement: PracticeFieldAssociationType,
): FieldFeature[] {
  let finalFields: FieldFeature[] = [];
  if (
    eligibleFields &&
    (coverCropping ||
      improvedTillage ||
      nitrogenOptimization ||
      zeroTillage ||
      pastureManagement)
  ) {
    const _coverCropping =
      coverCropping &&
      coverCropping.selectedFields.filter((fl) => fl.selected === true);
    const _improvedTillage =
      improvedTillage &&
      improvedTillage.selectedFields.filter((fl) => fl.selected === true);
    const _nitrogenOptimization =
      nitrogenOptimization &&
      nitrogenOptimization.selectedFields.filter((fl) => fl.selected === true);
    const _zeroTillage =
      zeroTillage &&
      zeroTillage.selectedFields.filter((fl) => fl.selected === true);
    const _pastureManagement =
      pastureManagement &&
      pastureManagement.selectedFields.filter((fl) => fl.selected === true);
    if (
      _zeroTillage &&
      _nitrogenOptimization &&
      _improvedTillage &&
      _coverCropping &&
      _pastureManagement
    ) {
      const allFields = [
        ..._zeroTillage,
        ..._nitrogenOptimization,
        ..._improvedTillage,
        ..._coverCropping,
        ..._pastureManagement,
      ];
      const filteredField: { [key: string]: FieldFeature } = {};
      allFields.forEach((fl) => {
        filteredField[fl.field.properties.fieldId] = fl.field;
      });
      finalFields = Object.keys(filteredField).map((key) => {
        return filteredField[key];
      });
    }
  }
  return finalFields;
}
export function getParsedNumber(area: Maybe<number> | undefined): number {
  if (area) {
    return parseFloat(area.toFixed(2));
  }
  return 0;
}

export const getLastFiveYearsList = (
  lowestYearInDB: number | null,
): number[] => {
  const currentYear = new Date().getFullYear();
  const yearStack: number[] = [];
  const lowestYear =
    lowestYearInDB && currentYear - 5 >= lowestYearInDB
      ? lowestYearInDB - 1
      : currentYear - 5;
  for (let i = currentYear; i > lowestYear; i--) {
    yearStack.push(i);
  }
  return yearStack;
};

export function yesNoDropDownValues() {
  return [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
}

export function returnDeleteColumnWidth(screenWidth: number) {
  let returnColValue: number;
  if (screenWidth <= 768) returnColValue = 1;
  else if (screenWidth > 768 && screenWidth <= 1024) returnColValue = 3;
  else if (screenWidth > 1024 && screenWidth <= 1440) returnColValue = 4;
  else if (screenWidth > 1440 && screenWidth <= 1920) returnColValue = 5;
  else returnColValue = 3;
  return returnColValue;
}

export function getPlantingMethods() {
  return [
    { label: 'Direct Seeded', value: 'direct-seeded' },
    { label: 'Broadcast', value: 'broadcast' },
  ];
}

export function getSoilAreaDistributedTypes() {
  return [
    { label: '< 10%', value: '< 10%' },
    { label: '10-30%', value: '10-30%' },
    { label: '> 30%', value: '> 30%' },
  ];
}

export function getDepthOfTillage() {
  return [
    { label: '0 - 2 inches', value: '0 - 2 inches' },
    { label: '2 - 5 inches', value: '2 - 5 inches' },
    { label: '> 5 inches', value: '> 5 inches' },
  ];
}

export function getImplementationTypes() {
  return [
    {
      label: 'Intensive/conventional tillage',
      value: 'intensive-conventional-tillage',
    },
    { label: 'Reduced Tillage', value: 'reduced-tillage' },
    { label: 'Mulch Tillage', value: 'mulch-tillage' },
    { label: 'Ridge Tillage', value: 'ridge-tillage' },
    { label: 'Strip Tillage', value: 'strip-tillage' },
    { label: 'No Tillage', value: 'no-tillage' },
    {
      label: 'Growing Season Cultivation',
      value: 'growing-season-cultivation',
    },
  ];
}

export function getTypeOfIrrigation() {
  return [
    {
      label: 'Furrow Diking - 90% Efficiency',
      value: 'furrow-diking-90%-Efficiency',
    },
    {
      label: 'Furrow/Flood - 65% Efficiency',
      value: 'furrow/Flood - 65% Efficiency',
    },
    { label: 'Sprinkler - 70% Efficiency', value: 'sprinkler-70%-efficiency' },
    { label: 'Drip - 85% Efficiency', value: 'drip-85%-efficiency' },
  ];
}

export function getTypeOfPesticides() {
  return [
    { label: 'Insecticide', value: 'Insecticide' },
    { label: 'Fungicide', value: 'Fungicide' },
    { label: 'Herbicide', value: 'Herbicide' },
  ];
}

export function getLiveStockTypePastureMgmt() {
  return [
    { label: 'Sheep', value: 'Sheep' },
    { label: 'Swine', value: 'Swine' },
    { label: 'Dairy Cattle', value: 'Dairy Cattle' },
    { label: 'Other cattle', value: 'Other cattle' },
    { label: 'Goats', value: 'Goats' },
    { label: 'Horses', value: 'Horses' },
    { label: 'Camels', value: 'Camels' },
    { label: 'Mules and Asses', value: 'Mules and Asses' },
    { label: 'Deer', value: 'Deer' },
    { label: 'Reindeer', value: 'Reindeer' },
    { label: 'Ostrich', value: 'Ostrich' },
    { label: 'Poultry', value: 'Poultry' },
    { label: 'Turkeys', value: 'Turkeys' },
    { label: 'Ducks', value: 'Ducks' },
    { label: 'Llamas', value: 'Llamas' },
    { label: 'Alpacas', value: 'Alpacas' },
    { label: 'Rabbits', value: 'Rabbits' },
    { label: 'Buffalo', value: 'Buffalo' },
    { label: 'Mink and Polecat', value: 'Mink and Polecat' },
    { label: 'Fur-bearing animals', value: 'Fur-bearing animals' },
    { label: 'Other', value: 'Other' },
  ];
}
export function getTerminationMethod() {
  return [
    { label: 'Mechanically', value: 'mechanically' },
    { label: 'Chemically', value: 'chemically' },
  ];
}

export function getDropDownValuesForCrop() {
  return [
    { label: 'Alfalfa', value: 'alfalfa' },
    { label: 'Annual Peanut', value: 'annual-peanut' },
    { label: 'Annual Rye', value: 'annual-rye' },
    { label: 'Annual Rye - Legume', value: 'annual-rye-legume' },
    {
      label: 'Annual Rye - Legume - Radish',
      value: 'annual-rye-legume-radish',
    },
    { label: 'Austrian Winter Pea', value: 'austrian-winter-pea' },
    { label: 'Barley', value: 'barley' },
    { label: 'Broccoli-Coast', value: 'broccoli-coast' },
    { label: 'Broccoli-Desert', value: 'broccoli-desert' },
    { label: 'Carrots', value: 'carrots' },
    { label: 'Cauliflower', value: 'cauliflower' },
    { label: 'Ceral Rye', value: 'ceral-rye' },
    { label: 'Clover', value: 'clover' },
    { label: 'Corn', value: 'corn' },
    { label: 'Corn Silage', value: 'corn-silage' },
    { label: 'Cotton', value: 'cotton' },
    { label: 'Crop', value: 'crop' },
    { label: 'Dry Field Beans', value: 'dry-field-beans' },
    { label: 'Dry Field Pea', value: 'dry-field-pea' },
    { label: 'Fallow', value: 'fallow' },
    { label: 'Forage Radish', value: 'forage-radish' },
    { label: 'Grass', value: 'grass' },
    { label: 'Grass-Legume Mix', value: 'grass-legume-mix' },
    { label: 'Lettuce-Head', value: 'lettuce-head' },
    { label: 'Lettuce-Leaf', value: 'lettuce-leaf' },
    { label: 'Lettuce-Romaine', value: 'lettuce-romaine' },
    { label: 'Millet', value: 'millet' },
    { label: 'Oats', value: 'oats' },
    { label: 'Oilseed Radish', value: 'oilseed-radish' },
    { label: 'Potato', value: 'potato' },
    { label: 'Rye', value: 'rye' },
    { label: 'Sorghum', value: 'sorghum' },
    { label: 'Sorghum Silage', value: 'sorghum-silage' },
    { label: 'Soybean', value: 'soybean' },
    { label: 'Spring Wheat', value: 'spring-wheat' },
    { label: 'Strawberry', value: 'strawberry' },
    { label: 'Sugar Beets', value: 'sugar-beets' },
    { label: 'Sunflower', value: 'sunflower' },
    { label: 'Switchgrass', value: 'switchgrass' },
    { label: 'Tomatoes, Fresh', value: 'tomatoes-fresh' },
    { label: 'Tomatoes, Processing', value: 'tomatoes-processing' },
    { label: 'Vetch', value: 'vetch' },
    { label: 'Winter Grain-Other', value: 'winter-grain-other' },
    { label: 'Winter Wheat', value: 'winter-wheat' },
  ];
}

export function omitDeep(obj: any): void {
  forIn(obj, function (value, key) {
    if (isObject(value)) {
      omitDeep(value);
    } else if (key === '__typename') {
      delete obj[key];
    } else if (key === 'selected') {
      delete obj[key];
    }
  });
}
export function getOwnerShip(
  ownerShipList: any[],  //[],
  field: FieldFeature,
): string {
  const selectedField = ownerShipList.filter(
    (element) =>
      element.fieldDetails.properties.fieldId === field.properties.fieldId,
  );
  if (selectedField && selectedField.length > 0) {
    return selectedField[0].fieldStatus;
  } else {
    return 'owned';
  }
}
export function getPracticeMutationData(
  ownerShipList: any[], //any[],  //[],
  practice: PracticeFieldAssociationType,
): EContractingFieldPracticeInput {
  let acresOwned = 0.0,
    acresLeased = 0.0,
    acresOtherContracts = 0.0;
  const selectedFieldOwnership = ownerShipList.filter((element) => {
    if (
      practice.selectedFields.find(
        (selectedField) =>
          selectedField.selected &&
          selectedField.field.properties.fieldId ===
            element.fieldDetails.properties.fieldId,
      )
    ) {
      return true;
    } else {
      return false;
    }
  });
  selectedFieldOwnership.forEach((element) => {
    if (element.fieldStatus === 'owned') {
      acresOwned =
        getParsedNumber(element.fieldDetails.properties.area) + acresOwned;
    } else if (element.fieldStatus === 'leased') {
      acresLeased =
        getParsedNumber(element.fieldDetails.properties.area) + acresLeased;
    } else if (element.fieldStatus === 'commercial') {
      acresOtherContracts =
        getParsedNumber(element.fieldDetails.properties.area) +
        acresOtherContracts;
    }
  });
  return {
    type: practice.practice.name,
    acresOwned,
    acresLeased,
    acresOtherContracts,
    implementationStartDate: practice.implementationStartDate
      ? practice.implementationStartDate
      : null,
    endOfGrowingSeason: practice.endOfGrowingSeason
      ? practice.endOfGrowingSeason
      : '',
    paymentOrPractiseAdoption: practice.paymentOrPractiseAdoption
      ? practice.paymentOrPractiseAdoption
      : '',
    fields: practice.selectedFields
      .filter((el) => el.selected)
      .map((shouldNotChangeField) => {
        const field = Object.create(shouldNotChangeField);
        omitDeep(field);
        const output: EContractingFieldInput = {
          acres: getParsedNumber(field.field.properties.area) || 0,
          fieldId: field.field.properties.fieldId,
          geometry: {
            geometry: field.field.geometry,
            properties: field.field.properties,
            type: field.field.type,
          },
          latitude: getLatitudeValue(field.field.geometry),
          longitude: getLongitudeValue(field.field.geometry),
          name: field.field.properties.name,
          ownership: getOwnerShip(ownerShipList, field.field),
        };
        return output;
      }),
  };
}

export function getSummaryParentValidationState(
  validationState: SummaryParentValidationState,
  practiceSelectionState: PracticeSelectionStateType,
): SummaryParentValidationState {
  if (
    practiceSelectionState.totalAcresSelected > 0 &&
    practiceSelectionState.totalFieldsEnrolled > 0 &&
    practiceSelectionState.totalPracticeSelected > 0 &&
    practiceSelectionState.practiceStartDate &&
    practiceSelectionState.practiceStartDate.length > 0
  ) {
    return {
      ...validationState,
      isPracticeSelectionValid: true,
    };
  } else {
    return {
      ...validationState,
      isPropertyRightsValid: false,
      isPracticeSelectionValid: false,
    };
  }
}

export function preparePropertyRightsState(
  contractData: EContracting,
  practiceSelectionState: PracticeSelectionStateType,
): IPropertyFieldForm {
  let landOwnerLeaseDetails: ILandOwnerDetails[] = [];
  if (contractData.landOwners) {
    landOwnerLeaseDetails = contractData.landOwners.map((owner) => {
      const leaseDetails: ILeaseDetails[] = owner.dates
        ? owner.dates.map((date) => {
            return {
              startDate: date.start ? new Date(date.start) : null,
              endDate: date.end ? new Date(date.end) : null,
            };
          })
        : [];
      const ownerObj: ILandOwnerDetails = {
        landOwnerName: owner.name,
        landOwnerAddress: owner.address.address,
        landOwnerZip: owner.address.zip,
        landOwnerState: owner.address.state,
        landOwnerCountry: owner.address.country,
        landOwnerCity: owner.address.city,
        landOwnerPhone: owner.phone,
        landOwnerEmail: owner.email,
        leaseDetails,
      };
      return ownerObj;
    });
  }
  const eligibleFields: ISelectField[] = getFieldsFromPracticeSelection(
    contractData.enrolledFields ? contractData.enrolledFields : undefined,
    practiceSelectionState.coverCropping,
    practiceSelectionState.improvedTillage,
    practiceSelectionState.nitrogenOptimization,
    practiceSelectionState.zeroTillage,
    practiceSelectionState.pastureManagement,
  ).map((field) => {
    return {
      fieldDetails: field,
      fieldId: field.properties.fieldId,
      fieldStatus: FieldOwnerShipFlags.NONE,
    };
  });

  eligibleFields.forEach((field) => {
    field.fieldStatus =
      getOwnerShipFromContractFields(
        contractData.fields,
        PracticeInfo.improvedTillage,
        field.fieldId,
      ) ||
      getOwnerShipFromContractFields(
        contractData.fields,
        PracticeInfo.coverCropping,
        field.fieldId,
      ) ||
      getOwnerShipFromContractFields(
        contractData.fields,
        PracticeInfo.zeroTillage,
        field.fieldId,
      ) ||
      getOwnerShipFromContractFields(
        contractData.fields,
        PracticeInfo.nitrogenOptimization,
        field.fieldId,
      ) ||
      getOwnerShipFromContractFields(
        contractData.fields,
        PracticeInfo.pastureManagement,
        field.fieldId,
      );
  });
  const OwnerShipQuestion =
    isAllFieldsHaveStatus(eligibleFields) && isAnyFieldsInLease(eligibleFields)
      ? 'no'
      : 'yes';
  const formData: IPropertyFieldForm = {
    landOwnerLeaseDetails: landOwnerLeaseDetails,
    eligibleFields: eligibleFields,
    OwnerShipQuestion,
  };

  return formData;
}

function getOwnerShipFromContractFields(
  contractData: EContractingFields,
  practice: PracticeInfoType,
  fieldId: string,
): string {
  let ownerShip = '';
  const fieldPractice = contractData.fieldPractices
    ? contractData.fieldPractices.find((field) => field.type === practice.name)
    : undefined;
  if (fieldPractice) {
    const foundField = fieldPractice.fields.find(
      (field) => field.fieldId === fieldId,
    );
    ownerShip = foundField ? foundField.ownership : '';
  }
  return ownerShip;
}

export function getPracticeSelection(
  fieldPractices: EContractingFieldPractice[],
  field: FieldFeature,
  practiceName: string,
) {
  let selection = false;
  const practiceObj = fieldPractices.find((practice) => {
    return practice.type === practiceName;
  });
  if (
    practiceObj &&
    practiceObj.fields &&
    practiceObj.fields.some(
      (_field) => _field.fieldId === field.properties.fieldId,
    )
  ) {
    selection = true;
  }
  return selection;
}

export function getAdditionalInfoPractise(
  fieldPractices: EContractingFieldPractice[],
  practiceName: string,
) {
  const practiceObj = fieldPractices.find((practice) => {
    return practice.type === practiceName;
  });

  return {
    implementationStartDate: practiceObj?.implementationStartDate
      ? practiceObj?.implementationStartDate
      : null,
    endOfGrowingSeason: practiceObj?.endOfGrowingSeason
      ? (practiceObj!.endOfGrowingSeason as EndOfGrowingSeasonType)
      : '',
    paymentOrPractiseAdoption: practiceObj?.paymentOrPractiseAdoption
      ? (practiceObj!
          .paymentOrPractiseAdoption as PaymentOrPractiseAdoptionType)
      : '',
  };
}

export function getPracticeObject(
  fieldSelections: FieldSelection[],
  practice: PracticeInfoType,
): PracticeFieldAssociationType {
  const practiceObj: PracticeFieldAssociationType = {
    acresEnrolled: returnTotalArea(fieldSelections),
    fieldsEnrolled: fieldSelections.filter((field) => field.selected).length,
    selectedFields: cloneDeep(fieldSelections),
    practice,
    implementationStartDate: null,
    endOfGrowingSeason: '',
    paymentOrPractiseAdoption: '',
  };
  return practiceObj;
}

// This function returns true if the given field ID is present in other practice, if true then disable it
export function checkFieldInOtherPractice(
  currentPractice: PracticeInfoType,
  fieldId: string,
  otherPractice: PracticeFieldAssociationType,
): boolean {
  if (currentPractice.name === PracticeInfo.improvedTillage.name) {
    return !!otherPractice.selectedFields.find(
      (field) => field.selected && field.field.properties.fieldId === fieldId,
    );
  } else if (currentPractice.name === PracticeInfo.zeroTillage.name) {
    return !!otherPractice.selectedFields.find(
      (field) => field.selected && field.field.properties.fieldId === fieldId,
    );
  }
  return false;
}

export function isoStringToDate(isoDate: string) {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  let month: number | string = date.getMonth() + 1;
  let dt: number | string = date.getDate();

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return new Date(month + '.' + dt + '.' + year);
}

export function getFormattedDate(paramDate: string) {
  const date = new Date(paramDate);

  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '.' + day + '.' + year;
}

export function removePastureMgmtTypeName(data: any) {
  if (data.__typename) {
    delete data.__typename;
    data.getPastureMgmtDetails.foreach((_: any, index: number) => {
      delete data.getPastureMgmtDetails[index].__typename;
    });
  }

  return data;
}

export function removePastureMgmtNotApplicableData(data: any) {
  if (data.isApplicablePastureMgmt === false) {
    data.getPastureMgmtDetails = [{}];
  }
  return data;
}

export function resetData(data: any) {
  const resetFarmData = { ...data };
  resetFarmData.getPastureMgmtDetails[0].householdCensus = [];
  resetFarmData.getPastureMgmtDetails[0] = [];
  return resetFarmData;
}
export async function calculateHouseHoldCensusData(data: any) {
  const resHouseholdCensus = data.getPastureMgmtDetails[0].householdCensus;
  const resLiveStockType = data.getPastureMgmtDetails[0].liveStockType;
  let finalhouseHoldCensusData: any;

  if (
    resLiveStockType &&
    resLiveStockType.length > 0 &&
    resHouseholdCensus &&
    Object.keys(resHouseholdCensus).length > 0
  ) {
    finalhouseHoldCensusData = Object.keys(resHouseholdCensus)
      .filter((item) => resLiveStockType.includes(item))
      .map((element: any) => {
        const returnedObj = {
          liveStockType: element,
          householdNumber: parseInt(
            resHouseholdCensus[element].householdNumber,
          ),
          averageWeight: parseFloat(resHouseholdCensus[element].averageWeight),
        };
        return returnedObj;
      });
  } else {
    finalhouseHoldCensusData = [];
  }
  return finalhouseHoldCensusData;
}

export const parseHouseHold = (formData: any) => {
  const response: { liveStockType: string; householdNumber: any; averageWeight: any; }[] = [];
  const value = formData.getPastureMgmtDetails[0];
  value.liveStockType.forEach((type: string)=>{
    response.push({
      liveStockType: type,
      householdNumber: value[`householdCensus.${type}.householdNumber`] ?
          Number(value[`householdCensus.${type}.householdNumber`]) : null,
      averageWeight: value[`householdCensus.${type}.averageWeight`] ?
          Number(value[`householdCensus.${type}.averageWeight`]) :null,
    })
  })
  return response
}

export async function pastureMgmtMutationParameter(
  data: any,
  userId: string,
  growerId: string,
  farmId: string,
  houseHoldData: any,
  updateRecordId: string,
) {
  const formDataValues = {
    userId,
    growerId,
    farmId: farmId,
    liveStockType: data.getPastureMgmtDetails[0].liveStockType
      ? data.getPastureMgmtDetails[0].liveStockType
      : [],
    generalAgeOfHerd: data.getPastureMgmtDetails[0].generalAgeOfHerd
      ? parseInt(data.getPastureMgmtDetails[0].generalAgeOfHerd)
      : null,
    startDateOfGrazing: data.getPastureMgmtDetails[0].startDateOfGrazing
      ? new Date(data.getPastureMgmtDetails[0].startDateOfGrazing)
      : null,
    endDateOfGrazing: data.getPastureMgmtDetails[0].endDateOfGrazing
      ? new Date(data.getPastureMgmtDetails[0].endDateOfGrazing)
      : null,
    calvingDate: data.getPastureMgmtDetails[0].calvingDate
      ? new Date(data.getPastureMgmtDetails[0].calvingDate)
      : null,
    conceptionRate: data.getPastureMgmtDetails[0].conceptionRate
      ? parseInt(data.getPastureMgmtDetails[0].conceptionRate)
      : null,
    averageBirthWeight: data.getPastureMgmtDetails[0].averageBirthWeight
      ? parseFloat(data.getPastureMgmtDetails[0].averageBirthWeight)
      : null,
    householdCensus: houseHoldData,
  };
  let variable = {};
  if (updateRecordId) {
    variable = {
      data: {
        _id: updateRecordId,
        formData: formDataValues,
      },
    };
  } else {
    variable = {
      data: {
        formData: formDataValues,
      },
    };
  }
  return variable;
}

export function getFieldLevelPercentage(
  yearWiseData: any,
  dataCollectedYears: any,
) {
  let fieldPercentage = 0;
  yearWiseData &&
    yearWiseData.forEach((y: any) => {
      fieldPercentage = fieldPercentage + y.completionPercentage;
    });
  return fieldPercentage / dataCollectedYears.length;
}

export const getFieldPercentage = (
  growerPercentageData: any[],
  grower: string,
  farmId: string,
  fieldId: string,
) => {
  const percentageData = growerPercentageData.filter(
    (grP) => grP.growerId === grower,
  );
  if (percentageData.length === 0) {
    return 0;
  }
  const FarmPercentage = percentageData[0].farms.filter(
    (grP: any) => grP.farmId === farmId,
  );
  if (FarmPercentage.length === 0) {
    return 0;
  }
  const FieldPercentage = FarmPercentage[0].fields.filter(
    (grP: any) => grP.fieldId === fieldId,
  );
  if (FieldPercentage.length === 0) {
    return 0;
  }
  return FieldPercentage[0].fieldPercentage;
};

export const getFarmPercentage = (
  growerPercentageData: any[],
  grower: string,
  farmId: string,
) => {
  const percentageData = growerPercentageData.filter(
    (grP) => grP.growerId === grower,
  );
  if (percentageData.length === 0) {
    return 0;
  }
  const FarmPercentage = percentageData[0].farms.filter(
    (grP: any) => grP.farmId === farmId,
  );
  if (FarmPercentage.length === 0) {
    return 0;
  }
  return FarmPercentage[0].farmPercentage;
};

export const getGrowerPercentage = (
  growerPercentageData: any[],
  grower: string,
) => {
  const percentageData = growerPercentageData.filter(
    (grP) => grP.growerId === grower,
  );
  if (percentageData.length > 0) {
    return percentageData[0].growerPercentage;
  } else {
    return 0;
  }
};
