// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { cursors } from '@mapbox/mapbox-gl-draw/src/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
import checkMarker, { Coordinates } from '../style/checkMarker';
import { MapboxEvent, MapMouseEvent } from 'mapbox-gl';

interface Polygon {
  updateCoordinate: (position: string, lng: number, lat: number) => void;
  coordinates: Coordinates[];
}

interface BaseFieldDrawModeState {
  currentVertexPosition: number;
  polygon: Polygon;
}

interface EventOrig {
  target: HTMLElement;
}

/*
please take a low at DrawPolygon mode from mapbox-gl-draw
when changing this
*/
const BaseFieldDrawMode = { ...DrawPolygon };

// checks if we are clicking on marker
BaseFieldDrawMode.isFirstVertex = function (
  state: BaseFieldDrawModeState,
  e: MapboxEvent<EventOrig>,
) {
  return e.originalEvent.target === checkMarker.getElement();
};

BaseFieldDrawMode.onMouseMove = function (
  state: BaseFieldDrawModeState,
  e: MapMouseEvent,
) {
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat,
  );
  // show check pointer above first vertex
  if (this.isFirstVertex(state, e)) {
    this.updateUIClasses({ mouse: cursors.POINTER });
  }
};

BaseFieldDrawMode.onClick = function (
  state: BaseFieldDrawModeState,
  e: MapMouseEvent,
) {
  // prevent double clicking the point with same lat and lng as previous one
  // caused errors on accidental double mouse clicks
  if (
    state.currentVertexPosition > 0 &&
    isEventAtCoordinates(
      e,
      state.polygon.coordinates[0][state.currentVertexPosition - 1],
    )
  ) {
    return;
  }

  // prevent clicking on marker when we have less that 3 point drawn
  if (state.currentVertexPosition < 3 && this.isFirstVertex(state, e)) {
    return;
  }

  if (state.currentVertexPosition === 0) {
    // show check marker on first vertex
    const firstVertex: Coordinates = [e.lngLat.lng, e.lngLat.lat];
    checkMarker.show(firstVertex, this.map);
  } else if (state.currentVertexPosition === 2) {
    // increase marker size after three drawn points, it means you can close the shape.
    checkMarker.changeSize(true);
  } else if (state.currentVertexPosition >= 3 && this.isFirstVertex(state, e)) {
    // closing shape only on marker click
    return this.clickOnVertex(state, e);
  }
  // continue drawing
  return this.clickAnywhere(state, e);
};
BaseFieldDrawMode.onTap = BaseFieldDrawMode.onClick;

BaseFieldDrawMode.superOnStop = DrawPolygon.onStop;
BaseFieldDrawMode.onStop = function (state: BaseFieldDrawModeState) {
  /*
        When stopping the drawing mode with less than 3 points drawn
        (even without having closed the shape), and leaving to a
        screen like the settings screen, or the farm switcher, there's an error:

        Uncaught (in promise) Error: simple_select is not valid
        at Object.changeMode (events.js:173)
        at push../node_modules/@mapbox/mapbox-gl-draw/src/modes/mode_interface_accessors.js.module.exports.push../node_modules/@mapbox/mapbox-gl-draw/src/modes/mode_interface_accessors.js.ModeInterface.changeMode (mode_interface_accessors.js:151)
        at push../node_modules/@mapbox/mapbox-gl-draw/src/modes/mode_interface_accessors.js.module.exports.push../node_modules/@mapbox/mapbox-gl-draw/src/modes/draw_polygon.js.DrawPolygon.onStop [as superOnStop] (draw_polygon.js:86)
        at push../node_modules/@mapbox/mapbox-gl-draw/src/modes/mode_interface_accessors.js.module.exports.push../src/services/drawing/modes/baseFieldDrawMode.js.BaseFieldDrawMode.onStop (baseFieldDrawMode.js:58)
        at Object.stop (object_to_mode.js:57)
        at Object.stop (mode_handler.js:57)
        at Object.changeMode (events.js:169)
        at push../node_modules/@mapbox/mapbox-gl-draw/index.js.module.exports.api.changeMode (api.js:167)
        at _callee15$ (mapbox.js:117)
        at s (index.js:499)
        at Generator._invoke (index.js:479)
        at Generator.e.<computed> [as next] (index.js:536)
        at asyncGeneratorStep (mapbox.js:3)
        at _next (mapbox.js:3)

        To not have the error make the app responsive to any sort of clicking
        (which was the case at least in dev mode)
    */
  try {
    this.superOnStop(state);
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.error(e);
  }
  // hide marker on exit drawing mode or after closing shape
  checkMarker.hide();
};

export default BaseFieldDrawMode;
