export const GET_FARM_QUERY = `
  query getFarmById($farmId: String!) {
    getFarmById(farmId: $farmId) {
      farm {
        farmId
        farmName
        legalRegistrationNumber
        address
        zip
        city
        state
        country
        email
        phone
      }
      user {
        userId
        email
        firstName
        lastName
        userRole
        companyId
      }
    }
  }
`;
