export const GET_FIELDS_WITH_ELIGIBILITY_QUERY = `
  query getFieldsWithLastEligibilityTest($farmId: String!) {
    getFieldsWithLastEligibilityTest(ListFieldsInput: { farmId: $farmId }) {
      eligibility {
        farmId
        fields
        eligibilityId
        isEligibilityTestCompleted
      }
      fields {
        geometry {
          type
          coordinates
        }
        properties {
          rawProperties
          fieldId
          farmId
          name
          area
        }
        type
      }
    }
  }
`;
