// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { activeStates } from '@mapbox/mapbox-gl-draw/src/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { isVertex } from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import EditField from './fieldEditMode';
import { MAP_MODES } from './modes';

const PointDeletion = { ...EditField };

// Delete vertex on click
PointDeletion.onVertex = function (state: any, event: any) {
  const vertex = event.featureTarget.properties;
  state.feature.removeCoordinate(vertex.coord_path);
  this.fireActionable(state);

  if (state.feature.isValid()) {
    this.fireUpdate();
  } else {
    this.deleteFeature([state.featureId]);
    this.changeMode(MAP_MODES.DRAW_FIELD);
  }
};

// Called to determine how to render the editable feature on the mapbox
PointDeletion.toDisplayFeatures = function (
  state: any,
  geojson: any,
  push: any,
) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = activeStates.ACTIVE;
    push(geojson);
    createSupplementaryPoints(geojson, {
      map: this.map,
      // Don't display midpoints since they cannot be deleted
      midpoints: false,
      selectedPaths: state.selectedCoordPaths,
    }).forEach(push);
  } else {
    geojson.properties.active = activeStates.INACTIVE;
    push(geojson);
  }
  this.fireActionable(state);
};

// Change the onVertex trigger from onMouseDown to onClick.
// This is to match the onVertex trigger of MAP_MODES.DRAW_FIELD.
// Without this, when deleting the last vertex and changing to MAP_MODES.DRAW_FIELD,
// a new vertex would be added automatically where the last point was deleted.
// This would occur because the MAP_MODES.DRAW_FIELD onClick/onVertex functions are called only
// when the mouse button is released, but we were changing the mode when the mouse button was pressed.
PointDeletion.onMouseDown = function () {
  return undefined;
};
PointDeletion.onTouchStart = PointDeletion.onMouseDown;

PointDeletion.onClick = function (state: any, event: any) {
  if (isVertex(event)) {
    return this.onVertex(state, event);
  }
  return undefined;
};
PointDeletion.onMouseDown = PointDeletion.onClick;

export default PointDeletion;
