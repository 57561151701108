import {
  ExtendedFeature,
  geoMercator,
  geoPath,
  GeoPermissibleObjects,
} from 'd3-geo';
import React, {FC} from 'react';

export function isClockWise(coords: Array<Array<number>>) {
  let sum = 0;
  for (let i = 0; i < coords.length; i++) {
    sum +=
      coords[i][0] * (coords[i + 1] || coords[0])[1] -
      (coords[i + 1] || coords[0])[0] * coords[i][1];
  }
  return sum >= 0;
}

// styled.svg<ISvg>`
//   stroke-width: 1px;
//
//   ${({ highLight, active, activeCustom, marginLeft }) =>
//   active
//     ? css({
//       fill: '#7ACF89',
//       alignContent: 'center',
//       textAlign: 'center',
//       stroke: '#C4BCBB26',
//       backgroundColor: '#7ACF8933',
//       paddingLeft: '0.5rem',
//       paddingTop: '0.5rem',
//       borderRadius: '0.5rem',
//     })
//     : activeCustom
//       ? css()
//       : highLight
//         ? css({
//           fill: '#00A1FF',
//           alignContent: 'center',
//           textAlign: 'center',
//           stroke: '#00A1FF',
//           backgroundColor: '#7A9CCF',
//           padding: '0.35rem',
//           borderRadius: '0.5rem',
//           marginLeft: marginLeft ? marginLeft : 'none',
//         })
//         : css({
//           fill: '#8A8283',
//           alignContent: 'center',
//           textAlign: 'center',
//           stroke: '#C4BCBB26',
//           borderRadius: '0.5rem',
//           backgroundColor: '#C4BCBB26',
//         })}
// `;

const transformFeatureIfAntiClockWise = (feature: any) => {
  if (feature.geometry.type.toLowerCase() === 'multipolygon') {
    feature.geometry.coordinates.forEach((_: any, index: string | number) => {
      const clockWise = isClockWise(feature.geometry.coordinates[index][0]);
      if (
        feature.geometry.coordinates &&
        feature.geometry.coordinates.length &&
        clockWise
      ) {
        feature.geometry.coordinates[index][0].reverse();
      }
    });
  } else {
    const clockWise = isClockWise(feature.geometry.coordinates[0]);
    if (
      feature.geometry.coordinates &&
      feature.geometry.coordinates.length &&
      feature.geometry.coordinates.length === 1 &&
      clockWise
    ) {
      feature.geometry.coordinates[0].reverse();
    }
  }
  return feature;
};

export const FieldIcon: FC<{
  feature: any,
  size?: number,
  active?: boolean,
}> = ({
  feature,
  size = 24,
  active = true,
}) => {
  const transformedFeature = transformFeatureIfAntiClockWise(feature);
  const d = React.useMemo(() => {
    const geoObject = transformedFeature as GeoPermissibleObjects;

    const projection = geoMercator();
    const geoGenerator = geoPath().projection(projection);
    projection.fitExtent(
      [
        [0, 0],
        [size, size],
      ],
      geoObject as ExtendedFeature,
    );
    return geoGenerator(geoObject) || undefined;
  }, [transformedFeature, size]);

  return (
    <svg
      height={`${size}px`}
      width={`${size}px`}
      style={active ? {
        fill: '#7ACF89',
        alignContent: 'center',
        textAlign: 'center',
        stroke: '#C4BCBB26',
        backgroundColor: '#E4F5E7',
        padding: `${size/4}px`,
        borderRadius: '0.5rem',
      } : {
        fill: '#8A8283',
        alignContent: 'center',
        textAlign: 'center',
        stroke: '#C4BCBB26',
        backgroundColor: '#C4BCBB26',
        padding: `${size/4}px`,
        borderRadius: '0.5rem',
      }}>
      <path d={d} />
    </svg>
  );
};
