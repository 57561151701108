import {useUserMetadata} from "../../hooks/useUserMetadata";
import {useLocation, useNavigate} from "react-router-dom";
import LoaderComponent from "../../components/loader/Loader.component";
import { useAuth0 } from '@auth0/auth0-react';
import { sha256 } from 'js-sha256';
import { CreateUserMutationVariables } from '../../graphql/mutations/create.user.mutation.graphql';
import {useMutation} from "urql";
import {CREATE_USER_MUTATION} from "../../components/onboarding-personal-info/queries/create.user.mutation";
import {User} from "../../graphql/types";
import {showToast} from "../../components/toast/toast";
import {useTranslation} from "react-i18next";
import {trackSignInSignUp} from "../../providers/RudderStackProvider/rudder.analytics.service";
import {RudderEventsTokens} from "../../providers/RudderStackProvider/tokens/rudder.events.tokens";
import  { Navigate } from 'react-router-dom';
import {useState} from "react";

const RedirectPage = () => {
  const {t} = useTranslation();
  const { getAccessTokenSilently, user } = useAuth0();
  const METADATA_KEY = `${process.env.REACT_APP_AUTH0_AUDIENCE}user_metadata`;
  const APP_METADATA_KEY = `${process.env.REACT_APP_AUTH0_AUDIENCE}app_metadata`;
  const [, createUserMutation] =
      useMutation<{ createUser: User }>(CREATE_USER_MUTATION);
  const userMetadata = useUserMetadata();
  const location = useLocation();
  const [url, setUrl] = useState<any>(null);
  const [CPUserChecked, setCPUserChecked] = useState<any>(false);
  const isCPUser = user![METADATA_KEY] && user![APP_METADATA_KEY];

  const checkCPUser = async () => {
    if(isCPUser && userMetadata.isNewUser && !CPUserChecked) {
      setCPUserChecked(true);
      await createCPUser(
          userMetadata.firstName,
          userMetadata.lastName,
          '',
          '',
          user!.email!,
          'ADVISOR',
          user![APP_METADATA_KEY].channelPartnerId,
      );
    }
  }

  const createCPUser = async (
      firstName: string,
      lastName: string,
      phoneNumber: string,
      language: string,
      email: string,
      userRole: string,
      companyId?: string,
  ) => {
    let webId = '';
    if (window.localStorage.getItem('webId')) {
      webId = '' + window.localStorage.getItem('webId');
    } else {
      webId = await sha256(email);
    }
    const variables: CreateUserMutationVariables = {
      firstName,
      lastName,
      phoneNumber,
      language,
      webId,
      email,
      userRole,
      companyId: companyId ? companyId : '',
    };

    try {
      const createUserResult = await createUserMutation(variables);
      if (createUserResult.data) {
        const resultData = createUserResult?.data?.createUser;

        const userId = resultData?.userId;
        if (userId) {
          await getAccessTokenSilently({
            ignoreCache: false,
          });
          let updatedUserMetadata = user![METADATA_KEY];
          updatedUserMetadata = updatedUserMetadata ? updatedUserMetadata : {};
          updatedUserMetadata['isNewUser'] = false;
          updatedUserMetadata['agoroUserId'] = resultData?.userId;
          updatedUserMetadata['email'] = resultData?.email;
          updatedUserMetadata['firstName'] = resultData?.firstName;
          updatedUserMetadata['language'] = resultData?.language;
          updatedUserMetadata['lastName'] = resultData?.lastName;
          updatedUserMetadata['phoneNumber'] = resultData?.phoneNumber;
          updatedUserMetadata['userRole'] = resultData?.userRole;
          updatedUserMetadata['companyId'] = resultData?.companyId
              ? resultData?.companyId
              : '';
          // setUserMetadata(updatedUserMetadata);
          showToast({
            message: t(
                'onboarding.onboardingPersonalInformation.toast.title.infoUpdated',
            ),
            title: t(
                'onboarding.onboardingPersonalInformation.toast.content.infoUpdatedSubTitle',
            ),
            type: "success",
            toastId: "Channel Partner created",
          });

          //  Analytics for CP Signed Up
          await trackSignInSignUp(
              updatedUserMetadata,
              location.pathname,
              RudderEventsTokens.TRACK_SIGNED_UP,
          );
        }
      } else {
        showToast({
          message: createUserResult.error?.message.split('[GraphQL] ')[1],
          type: "error",
          title: t('common.errorTitle'),
          toastId: "Channel partner creation error",
        });
      }
    } catch (err) {
      showToast({
        message: t('common.unexpectedError'),
        type: "error",
        title: t('common.unexpectedError'),
        toastId: "Channel partner unexpected error",
      });
    }
  };

  checkCPUser().then(() => {
    if (userMetadata.isNewUser && !isCPUser) {
      setUrl('/onboarding-personal-information');
    } else if (userMetadata.userRole === 'ADVISOR' || isCPUser) {
      setUrl('/growers');
    } else {
      setUrl(`/growers/${userMetadata.agoroUserId}/farms`);
    }
  });

  return !url ? <LoaderComponent/> : <Navigate replace to={url}/>;
};

export default RedirectPage;
