import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {useUserMetadata} from "../hooks/useUserMetadata";
import {useEffect, useState} from "react";
import {Heading} from "grommet";
import {Alert} from "grommet-icons";

export enum Roles {
  ADVISOR = 'advisor',
  GROWER = 'grower'
}

interface AuthGuardParams {
  requiredRoles?: Roles[],
  children: any
}

const AuthGuard = (props: AuthGuardParams) => {

  const {isAuthenticated} = useAuth0();
  const user = useUserMetadata();

  const errorContent = () => <div style={{textAlign: "center", marginTop: '2rem'}}>
    <Alert/>
    <h1 style={{fontSize: '23px', lineHeight: '27px'}}>Sorry, you do not have permission to access this resource.</h1>
  </div>;

  if (!isAuthenticated) {
    return errorContent();
  }

  if (props.requiredRoles && props.requiredRoles.indexOf(user.userRole) < 0) {
    return errorContent();
  }


  return props.children;
};

export default AuthGuard;
