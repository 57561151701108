import { ISelectField } from './PropertyRightInterfaces';

export const isAnyFieldsInLease: (
  fields: ISelectField[],
) => ISelectField | undefined = (fields) => {
  return fields.find(
    (fl) =>
      fl && (fl.fieldStatus === 'leased' || fl.fieldStatus === 'commercial'),
  );
};

export const isAllFieldsHaveStatus: (fields: ISelectField[]) => boolean = (
  fields,
) => {
  const statusNotSelected = fields.filter(
    (fl) => fl && (fl.fieldStatus === undefined || fl.fieldStatus === ''),
  );
  return statusNotSelected.length === 0 ? true : false;
};

export const getPolygonCenterCoordinateAsAPoint = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  polygon: any,
): {
  type: string;
  coordinates: number[];
} => {
  let minx = 1000;
  let miny = 1000;
  let maxx = -1000;
  let maxy = -1000;
  if ('geometry' in polygon) polygon = polygon.geometry;
  if (polygon.type.toLowerCase() === 'polygon') {
    polygon = polygon.coordinates[0];

    for (let i = 0; i < polygon.length; i++) {
      const point = polygon[i];
      const x = point[0];
      const y = point[1];

      if (x < minx) minx = x;
      else if (x > maxx) maxx = x;
      if (y < miny) miny = y;
      else if (y > maxy) maxy = y;
    }
  } else {
    polygon = polygon.coordinates;
    polygon.map((poly: any[]) => {
      for (let i = 0; i < poly[0].length; i++) {
        const point = poly[0][i];
        const x = point[0];
        const y = point[1];

        if (x < minx) minx = x;
        else if (x > maxx) maxx = x;
        if (y < miny) miny = y;
        else if (y > maxy) maxy = y;
      }
    });
  }
  return {
    type: 'Point',
    coordinates: [minx + (maxx - minx) / 2, miny + (maxy - miny) / 2],
  };
};
