import { Box } from '@agorocarbon/box';
import { Text } from '@agorocarbon/text';
import { TextInput } from '@agorocarbon/text-input';
import { FormField, FormFieldExtendedProps } from 'grommet';
import React, { useEffect, useState } from 'react';

export interface AgTextFormFieldProps extends FormFieldExtendedProps {
  fieldId: string;
  shortName?: string;
  minLength?: any;
  errors?: any;
  maxLength?: any;
  isRequired?: any;
  hasError?: boolean;
  regex?: any;
  value?: string;
}

const formLabel = (label: string | React.ReactNode, error = false) => {
  if (typeof label === 'string') {
    return (
      <Box alignSelf="start">
        <Text
          margin={{ left: '-8px' }}
          textAlign="start"
          weight="bold"
          color={error ? 'rgb(188, 0, 31)' : 'black'}
        >
          {label}
        </Text>
      </Box>
    );
  } else {
    return label;
  }
};

const formPlaceHolder = (placeholder: string | React.ReactNode) => {
  if (typeof placeholder === 'string') {
    return (
      <Box alignSelf="start">
        <Text size="large" textAlign="start" weight="lighter" color="gray">
          {placeholder}
        </Text>
      </Box>
    );
  } else {
    return placeholder;
  }
};
const formError = (placeholder: string | React.ReactNode) => {
  if (typeof placeholder === 'string') {
    return (
      <Box alignSelf="start">
        <Text
          margin={{ left: '-8px' }}
          textAlign="start"
          weight="lighter"
          color="rgb(188, 0, 31)"
        >
          {placeholder}
        </Text>
      </Box>
    );
  } else {
    return placeholder;
  }
};

const getRuleConfig = (input: any, defaultMessage: any) => {
  if (typeof input !== 'object' || input instanceof RegExp) {
    return {
      value: input,
      message: formError(defaultMessage),
    };
  }
  return { value: input.value, message: formError(input.message) };
};

function parseValidations(props: AgTextFormFieldProps) {
  const fieldName = props.shortName || props.label;
  const validate = [];
  const { maxLength, minLength, regex, isRequired } = props;
  const isRequiredConfig = getRuleConfig(
    isRequired,
    `Please enter ${fieldName}.`
  );
  if (isRequiredConfig.value) {
    validate.push((value: string) => {
      if (!value) {
        return isRequiredConfig.message;
      }
    });
  }
  const maxLengthConfig = getRuleConfig(
    maxLength,
    `${fieldName} should not exceed ${maxLength} characters.`
  );
  if (maxLengthConfig.value) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    validate.push((value: string) => {
      if (value && value.length > maxLengthConfig.value) {
        return maxLengthConfig.message;
      }
    });
  }

  const minLengthConfig = getRuleConfig(
    minLength,
    `${fieldName} should be of minimum ${minLength} characters.`
  );
  if (minLengthConfig.value) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    validate.push((value: string) => {
      if (value && value.length < minLengthConfig.value) {
        return minLengthConfig.message;
      }
    });
  }

  const regexConfig = getRuleConfig(regex, `Please enter valid ${fieldName}.`);
  if (regexConfig.value) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    validate.push((value: string) => {
      if (!value.match(regexConfig.value)) {
        return regexConfig.message;
      }
    });
  }
  if (props.validate) {
    if (typeof props.validate === 'function') {
      validate.push(props.validate);
    } else if (Array.isArray(props.validate)) {
      validate.push(...props.validate);
    }
  }
  return validate;
}

export function AgTextFormField({ ...props }: AgTextFormFieldProps) {
  props.validate = parseValidations(props);
  const [label, setLabel] = useState<any>(null);
  useEffect(() => {
    if (props.label) {
      const hasError =
        props.hasError ||
        !!props.errors?.find((error: string) => error === props.name);
      const calculatedFormLabel = formLabel(props.label, hasError);
      setLabel(calculatedFormLabel);
    }
  }, [props.errors]);

  if (props.placeholder) {
    props.placeholder = formPlaceHolder(props.placeholder);
  }

  const [value, setValue] = useState(props.value);

  const onChangeHandler = (event: any) => {
    setValue(event.target.value);
  };

  // TODO: If we put the values and onchange handler directly in the text input field the values don't change based on controlled form values.  We should make a more explicit mechanism to indicate whether this is a controlled field or uncontrolled field
  const getUncontrolledValues = (value: string | undefined) => {
    if (value) {
      return {
        value,
        onChange: onChangeHandler,
      };
    }
    return {};
  };

  return (
    <FormField
      {...props}
      label={label}
      contentProps={{  }}
    >
      <TextInput className={'ag-text-field'}
        {...getUncontrolledValues(value)}
        plain
        size="xlarge"
        disabled={props.disabled}
        name={props.name}
        id={props.fieldId}
        placeholder={props.placeholder}
        style={{ fontWeight: 'normal', border: 'solid 1px #1370BC', fontSize: '16px',lineHeight: '24px', color: '#191408', borderBottom: '1px', padding: '8px'}}
      />
    </FormField>
  );
}

export default AgTextFormField;
