import gql from 'graphql-tag';

export interface CreateNewGrowerMutationParams{
    farmName: string
    firstName: string
    lastName: string
    growerEmail: string
    isSendInvite: boolean
    isLocal: boolean
}

export const CREATE_GROWER_WITH_FARM =gql `
    mutation CreateGrowerWithFarm(
        $farmName: String!
        $firstName: String!
        $lastName: String!
        $growerEmail: String!
        $isSendInvite: Boolean!
        $isLocal: Boolean!
    ) {
        createGrowerWithFarm(
            createGrowerWithFarmInput: {
                farmName: $farmName
                firstName: $firstName
                lastName: $lastName
                growerEmail: $growerEmail
                isSendInvite: $isSendInvite
                isLocal: $isLocal
            }
        ) {
            user {
                userId
                firstName
                lastName
                email
                companyId
            }
            farm {
                farmId
                farmName
            }
            invite {
                inviteId
                email
            }
        }
    }
`;
