/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as RudderAnalyticsService from 'rudder-sdk-js';
import { sha256 } from 'js-sha256';

export function rudderInitialize(): void {
  RudderAnalyticsService.load(
    '' + process.env['REACT_APP_RUDDERSTACK_KEY'],
    '' + process.env['REACT_APP_RUDDERSTACK_DATA_URL'],
  );
}

function getAliasId(email = '') {
  if (window.localStorage.getItem('webId')) {
    return '' + window.localStorage.getItem('webId');
  } else {
    return sha256(email);
  }
}

export type RudderEventPropertiesType = {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  tag?: string;
  description?: string;
  userRole?: string;
  extraContent?: string;
  channelPartnerId?: string;
  fieldDetails?: {
    fieldCount?: number;
    acresCount?: number;
    fieldCountry?: string;
    fieldCountryCode?: string;
  };
};

export function eventAlias(to: string, from?: string, options?: any): void {
  RudderAnalyticsService.alias(to, from, options, () => {
    console.log('in alias call');
  });
}

export async function trackSignInSignUp(
  userMetaData: any,
  pathname: string,
  eventName: string,
) {
  if (!localStorage.getItem('auth0CypressIdData')) {
    await eventIdentify(
      userMetaData.agoroUserId,
      {
        //traits
        name: `${userMetaData.firstName} ${userMetaData.lastName}`,
        email: userMetaData.email,
        id: userMetaData.agoroUserId,
        description: userMetaData.userRole,
        userId: await getAliasId(userMetaData.email),
        channelPartnerId: userMetaData.companyId,
      },
      {
        //page
        page: {
          path: pathname,
        },
      },
    );

    const aliasID = await getAliasId(userMetaData.email);
    await eventAlias(userMetaData.agoroUserId, aliasID);
    await eventTrack(eventName, {
      userId: userMetaData.agoroUserId,
      userRole: userMetaData.userRole,
      channelPartnerId: userMetaData.companyId,
      extraContent: 'aliasID:' + aliasID,
    });
  }
}

export async function eventIdentify(
  id: string,
  traits: any = {},
  options: any = {},
) {
  if (!traits) {
    traits = { userId: await getAliasId() };
  } else {
    if (traits && !traits.userId) {
      traits.user = await getAliasId();
    }
  }
  RudderAnalyticsService.identify(
    id,
    { ...traits },
    {
      page: {
        path: options?.page?.path,
      },
    },
    () => {
      console.log(`Identify, id=${id}`);
    },
  );
}

export async function eventPage(
  pageName: string,
  category: string,
  properties: any,
  context: any = {},
): Promise<string> {
  properties = await extractUserIdProperties(properties);
  return new Promise((resolve) => {
    RudderAnalyticsService.page(category, pageName, properties, context, () => {
      resolve(`Page tracked, webId=${JSON.stringify(properties.userId)}`);
    });
  });
}

async function extractUserIdProperties(properties: any) {
  if (properties) {
    properties.userId = await getAliasId();
  } else {
    properties = { userId: await getAliasId() };
  }
  return properties;
}

export async function eventTrack(
  eventName: string,
  properties: any = {},
): Promise<string> {
  properties = await extractUserIdProperties(properties);
  return new Promise((resolve) => {
    RudderAnalyticsService.track(eventName, properties, {}, () => {
      resolve(`Event tracked, webId=${JSON.stringify(properties.userId)}`);
    });
  });
}
