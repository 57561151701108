import React from 'react';
import {Helmet} from 'react-helmet-async';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {I18nextProvider} from "react-i18next";
import LoaderComponent from "./components/loader/Loader.component";
import AppRouter from "./pages/App.router";
import {RudderStackProvider} from "./providers/RudderStackProvider/RudderStackProvider";
import i18n from './i18n/i18n';
import {UrqlProviderWrapper} from "./utils/UrqlProviderWrapper";
import './App.css';

if (process.env["REACT_APP_SENTRY_DNS"]) {
  Sentry.init({
    dsn: process.env['REACT_APP_SENTRY_DNS'],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: Number.parseFloat(
      '' + process.env['REACT_APP_SENTRY_TRACE_RATE'],
    ),
  });
}

const userSnap = <script type='text/javascript'>
  {`
             window.onUsersnapCXLoad = function (api) {
                api.init();
                api.on('open', function(event) {
                let email = window.localStorage.getItem('userEmail');
                if (email && email !== ''){
                event.api.setValue('visitor', email);
                }
              });
              };
              var script = document.createElement('script');
              script.defer = 1;
              script.src =
                'https://widget.usersnap.com/load/${process.env['REACT_APP_USERSNAP_KEY']}?onload=onUsersnapCXLoad';
              document.getElementsByTagName('head')[0].appendChild(script);
        `}
</script>;

function App() {
  return (
    <div className="App">
      <Helmet>
        {userSnap}
      </Helmet>
      <UrqlProviderWrapper>
        <RudderStackProvider>
          <I18nextProvider i18n={i18n}>
            <AppRouter/>
          </I18nextProvider>
        </RudderStackProvider>
      </UrqlProviderWrapper>
    </div>
  );
}

export default App;
// export default withAuthenticationRequired(App, {
//   onRedirecting: () => (
//     <div>
//       <LoaderComponent/>
//     </div>
//   ),
// });
