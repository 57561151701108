import { cloneDeep } from 'lodash';
import { FieldFeature } from '../../graphql/types';
import {
  EndOfGrowingSeasonType,
  FieldSelection,
  PaymentOrPractiseAdoptionType,
  PracticeSelectionStateType,
} from '../ESignatureFormTypes/PracticeFieldAssociationType';
import PracticeInfo from '../ESignatureFormTypes/PractiseInfo.json';
import { checkFieldInOtherPractice } from './helpers';

export enum PracticeTypes {
  IMPROVED_FIELD_SELECTED = 'IMPROVED_FIELD_SELECTED',
  ZERO_TILLAGE_FIELD_SELECTED = 'ZERO_TILLAGE_FIELD_SELECTED',
  COVER_CROPPING_FIELD_SELECTED = 'COVER_CROPPING_FIELD_SELECTED',
  NITROGEN_OPTIMIZATION_FIELD_SELECTED = 'NITROGEN_OPTIMIZATION_FIELD_SELECTED',
  PASTURE_MANAGEMENT_SELECTED = 'PASTURE_MANAGEMENT_SELECTED',
}
export enum FieldActionTypes {
  FIELD_SELECTED = 'FIELD_SELECTED',
  FIELD_DE_SELECTED = 'FIELD_DE_SELECTED',
  INITIAL_STATE = 'INITIAL_STATE',
  MODIFY_ALL = 'MODIFY_ALL',
  DATE_SELECTED = 'DATE_SELECTED',
  PRACTICE_DATE_SELECTED = 'PRACTICE_DATE_SELECTED',
  SET_PLATFILE = 'SET_PLATFILE',
  SET_DIRTY = 'SET_DIRTY',
  SET_END_OF_GROWING_SEASON = 'SET_END_OF_GROWING_SEASON',
  SET_PAYMENT_OR_PRACTISE_ADOPTION = 'SET_PAYMENT_OR_PRACTISE_ADOPTION',
}

export interface SetPlatfile {
  type: FieldActionTypes.SET_PLATFILE;
  platfile: string;
}

export interface ModifyAll {
  type: FieldActionTypes.MODIFY_ALL;
  payload: {
    selectAll: boolean;
    practiceType: string;
  };
}

export interface FieldSelected {
  type: FieldActionTypes.FIELD_SELECTED;
  field: FieldFeature;
  practiceType: string;
}

export interface FieldDeSelected {
  type: FieldActionTypes.FIELD_DE_SELECTED;
  field: FieldFeature;
  practiceType: string;
}

export interface InitialState {
  type: FieldActionTypes.INITIAL_STATE;
  payload: PracticeSelectionStateType;
}

export interface SelectDate {
  type: FieldActionTypes.DATE_SELECTED;
  payload: string;
}
export interface PracticeImplementationStartDate {
  type: FieldActionTypes.PRACTICE_DATE_SELECTED;
  payload: string | null;
  practiceType: string;
}

export interface EndOfGrowingSeasonAction {
  type: FieldActionTypes.SET_END_OF_GROWING_SEASON;
  payload: EndOfGrowingSeasonType;
  practiceType: string;
}

export interface PaymentOrPractiseAction {
  type: FieldActionTypes.SET_PAYMENT_OR_PRACTISE_ADOPTION;
  payload: PaymentOrPractiseAdoptionType;
  practiceType: string;
}

export interface MarkAsDirty {
  type: FieldActionTypes.SET_DIRTY;
}

export type FieldActions =
  | SetPlatfile
  | FieldSelected
  | SelectDate
  | FieldDeSelected
  | InitialState
  | MarkAsDirty
  | ModifyAll
  | PracticeImplementationStartDate
  | EndOfGrowingSeasonAction
  | PaymentOrPractiseAction;

/**
 * Handles how that state changes in the `Practice` component.
 */
export function practiceReducer(
  state: PracticeSelectionStateType,
  action: FieldActions,
): PracticeSelectionStateType {
  switch (action.type) {
    case FieldActionTypes.INITIAL_STATE:
      return { ...action.payload };
    case FieldActionTypes.FIELD_SELECTED:
      return getUpdatedStateFromSelection(state, action);
    case FieldActionTypes.FIELD_DE_SELECTED:
      return getUpdatedStateFromDeSelection(state, action);
    case FieldActionTypes.MODIFY_ALL:
      return modifyAllFields(state, action);
    case FieldActionTypes.DATE_SELECTED:
      return {
        ...state,
        isDirty: true,
        practiceStartDate: action.payload,
      };
    case FieldActionTypes.PRACTICE_DATE_SELECTED:
      return modifyPracticeImplementationDate(state, action);
    case FieldActionTypes.SET_END_OF_GROWING_SEASON:
      return modifyEndOfGrowingSeason(state, action);
    case FieldActionTypes.SET_PAYMENT_OR_PRACTISE_ADOPTION:
      return modifyPaymentOrPractiseAdoption(state, action);
    case FieldActionTypes.SET_PLATFILE:
      return {
        ...state,
        isDirty: true,
        platfile: action.platfile,
      };
    case FieldActionTypes.SET_DIRTY:
      return {
        ...state,
        isDirty: true,
      };
  }
}

function getUpdatedStateFromSelection(
  state: PracticeSelectionStateType,
  action: FieldSelected,
): PracticeSelectionStateType {
  return modifyState(true, state, action);
}

function getUpdatedStateFromDeSelection(
  state: PracticeSelectionStateType,
  action: FieldDeSelected,
): PracticeSelectionStateType {
  return modifyState(false, state, action);
}

function modifyState(
  selection: boolean,
  state: PracticeSelectionStateType,
  action: FieldSelected | FieldDeSelected,
): PracticeSelectionStateType {
  const stateCopy = cloneDeep(state);
  if (action.practiceType === PracticeInfo.improvedTillage.key) {
    stateCopy.improvedTillage.selectedFields
      .filter(
        (element) =>
          element.field.properties.fieldId === action.field.properties.fieldId,
      )
      .forEach((selectedField) => {
        return (selectedField.selected = selection);
      });

    if (
      stateCopy.improvedTillage.selectedFields &&
      stateCopy.improvedTillage.selectedFields.filter(
        (fl) => fl.selected === true,
      ).length === 0
    ) {
      stateCopy.improvedTillage.endOfGrowingSeason = '';
      stateCopy.improvedTillage.paymentOrPractiseAdoption = '';
      stateCopy.improvedTillage.implementationStartDate = null;
    }

    stateCopy.improvedTillage.acresEnrolled = returnTotalArea(
      stateCopy.improvedTillage.selectedFields,
    );
    stateCopy.improvedTillage.fieldsEnrolled =
      stateCopy.improvedTillage.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (action.practiceType === PracticeInfo.coverCropping.key) {
    stateCopy.coverCropping.selectedFields
      .filter(
        (element) =>
          element.field.properties.fieldId === action.field.properties.fieldId,
      )
      .forEach((selectedField) => {
        return (selectedField.selected = selection);
      });

    if (
      stateCopy.coverCropping.selectedFields &&
      stateCopy.coverCropping.selectedFields.filter(
        (fl) => fl.selected === true,
      ).length === 0
    ) {
      stateCopy.coverCropping.endOfGrowingSeason = '';
      stateCopy.coverCropping.paymentOrPractiseAdoption = '';
      stateCopy.coverCropping.implementationStartDate = null;
    }
    stateCopy.coverCropping.acresEnrolled = returnTotalArea(
      stateCopy.coverCropping.selectedFields,
    );
    stateCopy.coverCropping.fieldsEnrolled =
      stateCopy.coverCropping.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (action.practiceType === PracticeInfo.zeroTillage.key) {
    stateCopy.zeroTillage.selectedFields
      .filter(
        (element) =>
          element.field.properties.fieldId === action.field.properties.fieldId,
      )
      .forEach((selectedField) => {
        return (selectedField.selected = selection);
      });

    if (
      stateCopy.zeroTillage.selectedFields &&
      stateCopy.zeroTillage.selectedFields.filter((fl) => fl.selected === true)
        .length === 0
    ) {
      stateCopy.zeroTillage.endOfGrowingSeason = '';
      stateCopy.zeroTillage.paymentOrPractiseAdoption = '';
      stateCopy.zeroTillage.implementationStartDate = null;
    }
    stateCopy.zeroTillage.acresEnrolled = returnTotalArea(
      stateCopy.zeroTillage.selectedFields,
    );
    stateCopy.zeroTillage.fieldsEnrolled =
      stateCopy.zeroTillage.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (action.practiceType === PracticeInfo.nitrogenOptimization.key) {
    stateCopy.nitrogenOptimization.selectedFields
      .filter(
        (element) =>
          element.field.properties.fieldId === action.field.properties.fieldId,
      )
      .forEach((selectedField) => {
        return (selectedField.selected = selection);
      });
    if (
      stateCopy.nitrogenOptimization.selectedFields &&
      stateCopy.nitrogenOptimization.selectedFields.filter(
        (fl) => fl.selected === true,
      ).length === 0
    ) {
      stateCopy.nitrogenOptimization.endOfGrowingSeason = '';
      stateCopy.nitrogenOptimization.paymentOrPractiseAdoption = '';
      stateCopy.nitrogenOptimization.implementationStartDate = null;
    }
    stateCopy.nitrogenOptimization.acresEnrolled = returnTotalArea(
      stateCopy.nitrogenOptimization.selectedFields,
    );
    stateCopy.nitrogenOptimization.fieldsEnrolled =
      stateCopy.nitrogenOptimization.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (action.practiceType === PracticeInfo.pastureManagement.key) {
    stateCopy.pastureManagement.selectedFields
      .filter(
        (element) =>
          element.field.properties.fieldId === action.field.properties.fieldId,
      )
      .forEach((selectedField) => {
        return (selectedField.selected = selection);
      });
    if (
      stateCopy.pastureManagement.selectedFields &&
      stateCopy.pastureManagement.selectedFields.filter(
        (fl) => fl.selected === true,
      ).length === 0
    ) {
      stateCopy.pastureManagement.endOfGrowingSeason = '';
      stateCopy.pastureManagement.paymentOrPractiseAdoption = '';
      stateCopy.pastureManagement.implementationStartDate = null;
    }
    stateCopy.pastureManagement.acresEnrolled = returnTotalArea(
      stateCopy.pastureManagement.selectedFields,
    );
    stateCopy.pastureManagement.fieldsEnrolled =
      stateCopy.pastureManagement.selectedFields.filter(
        (field) => field.selected,
      ).length;
  }

  const { totalAcresSelected, totalFieldsEnrolled, totalPracticeSelected } =
    updateCounts(stateCopy);
  return {
    ...stateCopy,
    totalAcresSelected,
    isDirty: true,
    totalFieldsEnrolled,
    totalPracticeSelected,
  };
}

export function updateCounts(stateCopy: PracticeSelectionStateType): {
  totalAcresSelected: number;
  totalFieldsEnrolled: number;
  totalPracticeSelected: number;
} {
  let totalFieldsEnrolled = 0;
  let totalAcresSelected = 0;
  for (
    let index = 0, n = stateCopy.improvedTillage.selectedFields.length;
    index < n;
    index++
  ) {
    if (
      stateCopy.improvedTillage.selectedFields[index].selected ||
      stateCopy.coverCropping.selectedFields[index].selected ||
      stateCopy.zeroTillage.selectedFields[index].selected ||
      stateCopy.nitrogenOptimization.selectedFields[index].selected ||
      stateCopy.pastureManagement.selectedFields[index].selected
    ) {
      totalAcresSelected =
        totalAcresSelected +
        (stateCopy.improvedTillage.selectedFields[index].field.properties
          .area || 0);
      totalFieldsEnrolled++;
    }
  }

  const isImprovedTillageSelected =
    stateCopy.improvedTillage.selectedFields.some((item) => item.selected)
      ? 1
      : 0;
  const isZeroTillageSelected = stateCopy.zeroTillage.selectedFields.some(
    (item) => item.selected,
  )
    ? 1
    : 0;
  const isCoverCroppingSelected = stateCopy.coverCropping.selectedFields.some(
    (item) => item.selected,
  )
    ? 1
    : 0;
  const isNitrogenOptimizationSelected =
    stateCopy.nitrogenOptimization.selectedFields.some((item) => item.selected)
      ? 1
      : 0;
  const isPastureManagementSelected =
    stateCopy.pastureManagement.selectedFields.some((item) => item.selected)
      ? 1
      : 0;
  const totalPracticeSelected =
    isImprovedTillageSelected +
    isZeroTillageSelected +
    isCoverCroppingSelected +
    isNitrogenOptimizationSelected +
    isPastureManagementSelected;

  return {
    totalAcresSelected,
    totalFieldsEnrolled,
    totalPracticeSelected,
  };
}

function modifyAllFields(
  state: PracticeSelectionStateType,
  action: ModifyAll,
): PracticeSelectionStateType {
  const stateCopy = cloneDeep(state);
  switch (action.payload.practiceType) {
    case PracticeInfo.improvedTillage.key:
      stateCopy.improvedTillage.selectedFields.forEach((item) => {
        if (
          !checkFieldInOtherPractice(
            stateCopy.improvedTillage.practice,
            item.field.properties.fieldId,
            stateCopy.zeroTillage,
          )
        ) {
          item.selected = action.payload.selectAll;
        }
      });
      if (
        stateCopy.improvedTillage.selectedFields &&
        stateCopy.improvedTillage.selectedFields.filter(
          (fl) => fl.selected === true,
        ).length === 0
      ) {
        stateCopy.improvedTillage.endOfGrowingSeason = '';
        stateCopy.improvedTillage.paymentOrPractiseAdoption = '';
        stateCopy.improvedTillage.implementationStartDate = null;
      }
      break;
    case PracticeInfo.coverCropping.key:
      stateCopy.coverCropping.selectedFields.forEach(
        (item) => (item.selected = action.payload.selectAll),
      );
      if (
        stateCopy.coverCropping.selectedFields &&
        stateCopy.coverCropping.selectedFields.filter(
          (fl) => fl.selected === true,
        ).length === 0
      ) {
        stateCopy.coverCropping.endOfGrowingSeason = '';
        stateCopy.coverCropping.paymentOrPractiseAdoption = '';
        stateCopy.coverCropping.implementationStartDate = null;
      }
      break;
    case PracticeInfo.zeroTillage.key:
      stateCopy.zeroTillage.selectedFields.forEach((item) => {
        if (
          !checkFieldInOtherPractice(
            stateCopy.zeroTillage.practice,
            item.field.properties.fieldId,
            stateCopy.improvedTillage,
          )
        ) {
          item.selected = action.payload.selectAll;
        }
      });
      if (
        stateCopy.zeroTillage.selectedFields &&
        stateCopy.zeroTillage.selectedFields.filter(
          (fl) => fl.selected === true,
        ).length === 0
      ) {
        stateCopy.zeroTillage.endOfGrowingSeason = '';
        stateCopy.zeroTillage.paymentOrPractiseAdoption = '';
        stateCopy.zeroTillage.implementationStartDate = null;
      }
      break;
    case PracticeInfo.nitrogenOptimization.key:
      stateCopy.nitrogenOptimization.selectedFields.forEach(
        (item) => (item.selected = action.payload.selectAll),
      );
      if (
        stateCopy.nitrogenOptimization.selectedFields &&
        stateCopy.nitrogenOptimization.selectedFields.filter(
          (fl) => fl.selected === true,
        ).length === 0
      ) {
        stateCopy.nitrogenOptimization.endOfGrowingSeason = '';
        stateCopy.nitrogenOptimization.paymentOrPractiseAdoption = '';
        stateCopy.nitrogenOptimization.implementationStartDate = null;
      }
      break;
    case PracticeInfo.pastureManagement.key:
      stateCopy.pastureManagement.selectedFields.forEach(
        (item) => (item.selected = action.payload.selectAll),
      );
      if (
        stateCopy.pastureManagement.selectedFields &&
        stateCopy.pastureManagement.selectedFields.filter(
          (fl) => fl.selected === true,
        ).length === 0
      ) {
        stateCopy.pastureManagement.endOfGrowingSeason = '';
        stateCopy.pastureManagement.paymentOrPractiseAdoption = '';
        stateCopy.pastureManagement.implementationStartDate = null;
      }
      break;
  }
  if (action.payload.practiceType === PracticeInfo.improvedTillage.key) {
    stateCopy.improvedTillage.acresEnrolled = returnTotalArea(
      stateCopy.improvedTillage.selectedFields,
    );
    stateCopy.improvedTillage.fieldsEnrolled =
      stateCopy.improvedTillage.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (action.payload.practiceType === PracticeInfo.coverCropping.key) {
    stateCopy.coverCropping.acresEnrolled = returnTotalArea(
      stateCopy.coverCropping.selectedFields,
    );
    stateCopy.coverCropping.fieldsEnrolled =
      stateCopy.coverCropping.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (action.payload.practiceType === PracticeInfo.zeroTillage.key) {
    stateCopy.zeroTillage.acresEnrolled = returnTotalArea(
      stateCopy.zeroTillage.selectedFields,
    );
    stateCopy.zeroTillage.fieldsEnrolled =
      stateCopy.zeroTillage.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (
    action.payload.practiceType === PracticeInfo.nitrogenOptimization.key
  ) {
    stateCopy.nitrogenOptimization.acresEnrolled = returnTotalArea(
      stateCopy.nitrogenOptimization.selectedFields,
    );
    stateCopy.nitrogenOptimization.fieldsEnrolled =
      stateCopy.nitrogenOptimization.selectedFields.filter(
        (field) => field.selected,
      ).length;
  } else if (
    action.payload.practiceType === PracticeInfo.pastureManagement.key
  ) {
    stateCopy.pastureManagement.acresEnrolled = returnTotalArea(
      stateCopy.pastureManagement.selectedFields,
    );
    stateCopy.pastureManagement.fieldsEnrolled =
      stateCopy.pastureManagement.selectedFields.filter(
        (field) => field.selected,
      ).length;
  }
  const { totalAcresSelected, totalFieldsEnrolled, totalPracticeSelected } =
    updateCounts(stateCopy);
  return {
    ...stateCopy,
    isDirty: true,
    totalAcresSelected,
    totalFieldsEnrolled,
    totalPracticeSelected,
  };
}

function modifyPracticeImplementationDate(
  state: PracticeSelectionStateType,
  action: PracticeImplementationStartDate,
): PracticeSelectionStateType {
  const stateCopy = cloneDeep(state);
  switch (action.practiceType) {
    case PracticeInfo.improvedTillage.key:
      stateCopy.improvedTillage.implementationStartDate = action.payload;
      break;
    case PracticeInfo.coverCropping.key:
      stateCopy.coverCropping.implementationStartDate = action.payload;
      break;
    case PracticeInfo.zeroTillage.key:
      stateCopy.zeroTillage.implementationStartDate = action.payload;
      break;
    case PracticeInfo.nitrogenOptimization.key:
      stateCopy.nitrogenOptimization.implementationStartDate = action.payload;
      break;
    case PracticeInfo.pastureManagement.key:
      stateCopy.pastureManagement.implementationStartDate = action.payload;
      break;
  }
  return {
    ...stateCopy,
    isDirty: true,
  };
}

function modifyEndOfGrowingSeason(
  state: PracticeSelectionStateType,
  action: EndOfGrowingSeasonAction,
): PracticeSelectionStateType {
  const stateCopy = { ...state };
  switch (action.practiceType) {
    case PracticeInfo.improvedTillage.key:
      stateCopy.improvedTillage.endOfGrowingSeason = action.payload;
      break;
    case PracticeInfo.coverCropping.key:
      stateCopy.coverCropping.endOfGrowingSeason = action.payload;
      break;
    case PracticeInfo.zeroTillage.key:
      stateCopy.zeroTillage.endOfGrowingSeason = action.payload;
      break;
    case PracticeInfo.nitrogenOptimization.key:
      stateCopy.nitrogenOptimization.endOfGrowingSeason = action.payload;
      break;
    case PracticeInfo.pastureManagement.key:
      stateCopy.pastureManagement.endOfGrowingSeason = action.payload;
      break;
  }
  return {
    ...stateCopy,
    isDirty: true,
  };
}

function modifyPaymentOrPractiseAdoption(
  state: PracticeSelectionStateType,
  action: PaymentOrPractiseAction,
): PracticeSelectionStateType {
  const stateCopy = cloneDeep(state);
  switch (action.practiceType) {
    case PracticeInfo.improvedTillage.key:
      stateCopy.improvedTillage.paymentOrPractiseAdoption = action.payload;
      break;
    case PracticeInfo.coverCropping.key:
      stateCopy.coverCropping.paymentOrPractiseAdoption = action.payload;
      break;
    case PracticeInfo.zeroTillage.key:
      stateCopy.zeroTillage.paymentOrPractiseAdoption = action.payload;
      break;
    case PracticeInfo.nitrogenOptimization.key:
      stateCopy.nitrogenOptimization.paymentOrPractiseAdoption = action.payload;
      break;
    case PracticeInfo.pastureManagement.key:
      stateCopy.pastureManagement.paymentOrPractiseAdoption = action.payload;
      break;
  }
  return {
    ...stateCopy,
    isDirty: true,
  };
}

export function returnTotalArea(fields: FieldSelection[]): number {
  return fields
    .filter((field) => field.selected)
    .map((field) => {
      return field.field.properties.area ? field.field.properties.area : 0;
    })
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
}
