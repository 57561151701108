import gql from 'graphql-tag';

export const GET_USER_EXIST_BY_EMAILID = gql`
    query getUserExistOrNot($emailId: String!) {
        getUserExistOrNot(emailId: $emailId) {
            userId
            firstName
            lastName
            email
            companyId
            userRole
            companyId
        }
    }
`;
