import gql from 'graphql-tag';

export const GET_ALL_ADVISORS_FOR_A_GROWER_QUERY = gql`
  query listAllAdvisorsForAGrower($growerUserId: String!) {
    listAllAdvisorsForAGrower(growerUserId: $growerUserId) {
      advisors {
        userId
        userRole
        firstName
        email
        lastName
        companyId
        status
        profession
        createdAt
      }
      grower {
        userId
        userRole
        firstName
        email
        lastName
        companyId
      }
    }
  }
`;
