import React, { ReactNode } from 'react';
import { IPropertyFieldForm } from '../components/PropertyRightComponents/PropertyRightInterfaces';
import { GeneralInformationType } from '../ESignatureFormTypes/GeneralInformationType';
import { PracticeSelectionStateType } from '../ESignatureFormTypes/PracticeFieldAssociationType';
import { practiceReducer } from '../state/PracticeSelection';
import ESignatureContext from './ESignatureContext';

type Props = {
  children: ReactNode;
};

const ESignatureProvider = ({ children }: Props): JSX.Element => {
  const [generalInfoState, setGeneralInfoState] =
    React.useState<GeneralInformationType>({} as GeneralInformationType);

  const [generalInfoStatedata, setGeneralInfoStatedata] = React.useState<any>(
    {} as any,
  );

  const [practiceSelectionState, dispatchPracticeSelectionState] =
    React.useReducer(practiceReducer, {} as PracticeSelectionStateType);
  const [propertyRightsState, setPropertyRightsState] =
    React.useState<IPropertyFieldForm>({} as IPropertyFieldForm);
  return (
    <ESignatureContext.Provider
      value={{
        generalInfoState,
        setGeneralInfoState,
        generalInfoStatedata,
        setGeneralInfoStatedata,
        practiceSelectionState,
        dispatchPracticeSelectionState,
        propertyRightsState,
        setPropertyRightsState,
      }}
    >
      {children}
    </ESignatureContext.Provider>
  );
};

export default ESignatureProvider;
