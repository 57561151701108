import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { UrqlProvider } from '@agorocarbon/urql-provider';
import loggerExchange from './urqlLoggerExchange';

export const UrqlProviderWrapper: React.FunctionComponent = ({ children }) => {
  const additionalExchanges =
    process.env.REACT_APP_LOG_GRAPHQL === 'true' ? [loggerExchange] : [];
  const { getAccessTokenSilently } = useAuth0();
  const getToken = React.useCallback<() => Promise<string>>(async () => {
    return getAccessTokenSilently({
      audience: `${process.env['REACT_APP_AUTH0_AUDIENCE']}`,
    });
  }, [getAccessTokenSilently]);

  return (
    <UrqlProvider
      additionalExchanges={additionalExchanges as any}
      url={process.env['REACT_APP_API_ENDPOINT'] || ''}
      getToken={getToken}
    >
      {children}
    </UrqlProvider>
  );
};
