export interface AcceptInviteMutationParams {
  userId: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  userRole: string;
  inviteId: string;
  phoneNumber: string;
  language: string;
}
export const ACCEPT_INVITE_MUTATION = `
  mutation AcceptInvite(
    $userId: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $userRole: String!
    $inviteId: String!
    $phoneNumber: String!
    $language: String!
  ) {
    acceptInvite(
      updateUserInput: {
        userId: $userId
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        userRole: $userRole
        inviteId: $inviteId
        auth0Id: ""
        phoneNumber: $phoneNumber
        language: $language
      }
    ) {
      userId
      auth0Id
      firstName
      lastName
      email
      userRole
      phoneNumber
      language
      token {
        id_token
        access_token
      }
    }
  }
`;
