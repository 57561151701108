export enum OwnerShipStatusFlag {
  NONE = 'NONE',
  YES = 'YES',
  NO = 'NO',
}

export enum FieldOwnerShipFlags {
  NONE = 'NONE',
  OWNED = 'owned',
  LEASED = 'leased',
  OTHER = 'commercial',
}

export enum PropertyRightTypes {
  INITIAL_STATE = 'INITIAL_STATE',
  UPDATE_COMMON_OWNERSHIP = 'UPDATE_COMMON_OWNERSHIP',
  UPDATE_FIELD_OWNERSHIP = 'UPDATE_FIELD_OWNERSHIP',
  INSERT_LAND_OWNER = 'INSERT_LAND_OWNER',
  INSERT_LEASE_DATE = 'INSERT_LEASE_DATE',
  SET_TOTAL_STATE = 'SET_TOTAL_FORM_VALUE',
}
// Actions declaration start
export interface SetInitialStateAction {
  // Initialize with empty arrays
  type: PropertyRightTypes.INITIAL_STATE;
  payload: PropertyRightsType;
}

export interface SetInitialStateAction {
  // Initialize with empty arrays
  type: PropertyRightTypes.INITIAL_STATE;
  payload: PropertyRightsType;
}

export interface SetTotalStateAction {
  // Initialize with empty arrays
  type: PropertyRightTypes.SET_TOTAL_STATE;
  payload: PropertyRightsType;
}

export interface UpdateCommonOwnerShipAction {
  type: PropertyRightTypes.UPDATE_COMMON_OWNERSHIP;
  payload: string;
}

export interface UpdateFieldOwnerShipAction {
  type: PropertyRightTypes.UPDATE_FIELD_OWNERSHIP;
  payload: FieldOwnerShipMap;
}

export interface InsertLandOwnerAction {
  type: PropertyRightTypes.INSERT_LAND_OWNER;
  payload: LandOwner;
}

export interface InsertLeaseDateAction {
  type: PropertyRightTypes.INSERT_LEASE_DATE;
  payload: {
    landOwner: LandOwner;
    leaseDate: LandOwnerLeaseDate;
  };
}

export type PropertyRightsActions =
  | SetInitialStateAction
  | UpdateCommonOwnerShipAction
  | InsertLeaseDateAction
  | InsertLandOwnerAction
  | UpdateFieldOwnerShipAction
  | SetTotalStateAction;
// Actions declaration end

// Model declaration start
export interface FieldOwnerShipMap {
  fieldDetails: any;
  fieldStatus: string;
}

export interface LandOwnerLeaseDate {
  startDate: string;
  endDate: string;
}

export interface LandOwner {
  key: string;

  landOwnerAddress: string;
  landOwnerName: string;
  landOwnerZip: string;
  landOwnerState: string;
  landOwnerCountry: string;
  landOwnerCity: string;
  landOwnerPhone: string;
  landOwnerEmail: string;
  leaseDetails: LandOwnerLeaseDate[];
}

export interface PropertyRightsType {
  OwnerShipQuestion: string;
  eligibleFields: FieldOwnerShipMap[];
  landOwnerLeaseDetails: LandOwner[];
}

// Model declaration end

export function propertyRightsReducer(
  state: PropertyRightsType,
  action: PropertyRightsActions,
): any {
  switch (action.type) {
    case PropertyRightTypes.INITIAL_STATE:
      return { ...action.payload };

    case PropertyRightTypes.SET_TOTAL_STATE:
      return { ...action.payload };

    case PropertyRightTypes.INSERT_LAND_OWNER: {
      const newOwner = { ...action.payload };
      const landOwnerList = [...state.landOwnerLeaseDetails, newOwner];
      return {
        ...state,
        landOwnerList,
      };
    }
    // case PropertyRightTypes.INSERT_LEASE_DATE:
    //   return {
    //     ...state,
    //     landOwnerList: insertAndGetLandOwners(state, action),
    //   };
    case PropertyRightTypes.UPDATE_COMMON_OWNERSHIP:
      return {
        ...state,
        ownerShipFlag: action.payload,
        fieldList: setAllAsOwned(state, action.payload),
      };
    // case PropertyRightTypes.UPDATE_FIELD_OWNERSHIP:
    //   return setFieldOwnerShip(state, action.payload);
  }
}

// function insertAndGetLandOwners(
//   state: PropertyRightsType,
//   action: InsertLeaseDateAction,
// ): LandOwner[] {
//   // insert date in land owner lease dates list:
//   // pass the landowner key and insert the date from payload in the state
//   const landOwnerList = [...state.landOwnerList];
//   for (let index = 0; index < landOwnerList.length; index++) {
//     const element = landOwnerList[index];
//     if (element.key === action.payload.landOwner.key) {
//       if (!element.leaseDates) {
//         element.leaseDates = [action.payload.leaseDate];
//       } else {
//         element.leaseDates.push(action.payload.leaseDate);
//       }
//     }
//   }
//   return landOwnerList;
// }
function setAllAsOwned(
  state: PropertyRightsType,
  ownerShip: string,
): FieldOwnerShipMap[] {
  // set owned in all fields : UI will hide the owner section on this state
  if (
    ownerShip === OwnerShipStatusFlag.NO ||
    ownerShip === OwnerShipStatusFlag.NONE
  ) {
    return [...state.eligibleFields];
  } else {
    const fieldList = state.eligibleFields.map((field) => {
      const updated = { ...field };
      updated.fieldStatus = FieldOwnerShipFlags.OWNED;
      return updated;
    });
    return fieldList;
  }
}

// function setFieldOwnerShip(
//   state: PropertyRightsType,
//   payload: FieldOwnerShipMap,
// ): PropertyRightsType {
//   // update field ownership flag in the list, pass the field in action payload and find and update it
//   //    if in new state all are owned, remove landowners from state if exists
//   const fieldList = [...state.fieldList];
//   for (let index = 0; index < fieldList.length; index++) {
//     const element = fieldList[index];
//     if (element.field.properties.fieldId === payload.field.properties.fieldId) {
//       element.ownerShipFlag = payload.ownerShipFlag;
//       break;
//     }
//   }
//   const landOwnerList = fieldList.every(
//     (element) => element.ownerShipFlag === FieldOwnerShipFlags.OWNED,
//   )
//     ? []
//     : [...state.landOwnerList];
//   return {
//     ...state,
//     fieldList,
//     landOwnerList,
//   };
// }
