// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import theme from '@mapbox/mapbox-gl-draw/src/lib/theme';

const SELECTED_FIELD_OUTLINE_WIDTH = 4;

let styles: any = {};
theme.forEach((i: any) => {
  styles[i.id] = i;
})

// this separate file from `layerStyles` seems to be required. Setting the layer styles there with e.g
// { id: 'gl-draw-polygon-fill-static.cold', source: 'mapbox-gl-draw-cold' } doesn't work
// the reason seems to be that the drawing styles are managed separately by `mapbox-gl-draw`
// https://github.com/mapbox/mapbox-gl-draw/blob/master/docs/API.md#styling-draw

// replace default orange with atfarm orange
const defaultOrange =
  styles['gl-draw-polygon-fill-active']['paint']['fill-color'];
styles = JSON.parse(
  JSON.stringify(styles).split(defaultOrange).join('#071019'),
);

// increase opacity of active field fill while editing
styles['gl-draw-polygon-fill-active']['paint']['fill-opacity'] = 0.75;
// styles['gl-draw-polygon-fill-active']['paint']['line-color'] = '#00a1ff';

// adjust size and color of inactive vertices
styles['gl-draw-polygon-and-line-vertex-inactive']['paint']['circle-color'] =
  '#00a1ff';
styles['gl-draw-polygon-and-line-vertex-inactive']['paint'][
  'circle-radius'
  ] = 3;
styles['gl-draw-polygon-and-line-vertex-stroke-inactive']['paint'][
  'circle-color'
  ] = '#00a1ff';
styles['gl-draw-polygon-and-line-vertex-stroke-inactive']['paint'][
  'circle-radius'
  ] = 7;

// adjust size and color of active vertices
styles['gl-draw-point-active']['paint']['circle-color'] = '#00a1ff';
styles['gl-draw-point-active']['paint']['circle-radius'] = 5;
styles['gl-draw-point-stroke-active']['paint']['circle-color'] = '#00a1ff';
styles['gl-draw-point-stroke-active']['paint']['circle-radius'] = 8;
styles['gl-draw-polygon-stroke-active']['paint']['line-color'] = '#00a1ff';
styles['gl-draw-polygon-stroke-active']['paint']['line-width'] = 2;

// style the drawn field when the mapbox mode switches back to static
styles['gl-draw-polygon-fill-static']['paint']['fill-opacity'] = 0.75;
styles['gl-draw-polygon-fill-static']['paint']['fill-color'] = '#071019';
styles['gl-draw-polygon-stroke-static']['paint']['line-color'] = '#00a1ff';
styles['gl-draw-polygon-stroke-static']['paint']['line-width'] =
  SELECTED_FIELD_OUTLINE_WIDTH;

// styles['gl-draw-polygon-and-line-vertex-stroke-inactive']['paint'][
//   'line-color'
// ] = '#00a1ff';
// styles['gl-draw-polygon-stroke-static']['paint']['line-color'] = '#00a1ff';
// styles['gl-draw-polygon-fill-static']['paint']['line-color'] = '#00a1ff';

// export array
export default Object.values(styles);
