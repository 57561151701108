export const FIND_CONTRACT_FOR_FARM = `
  query findContractForFarm($farmId: String!) {
    findContractForFarm(farmId: $farmId) {
      contractId
      draftContractId
      farmId
      status
      grower {
        userId
        firstName
        lastName
        title
        legalRegistrationNumber
        email
      }
      company {
        name
        address {
          address
          city
          state
          zip
          country
        }
        email
        phone
      }
      fields {
        acresTotal
        acresOwned
        acresLeased
        acresOtherContracts
        fieldPractices {
          type
          acresOwned
          acresLeased
          acresOtherContracts
          implementationStartDate
          fields {
            fieldId
            geometry {
              id
              type
              properties {
                name
                farmId
                fieldId
                rawProperties
                area
              }
              geometry {
                type
                coordinates
              }
            }
            ownership
            name
            acres
            latitude
            longitude
          }
        }
      }
      landOwners {
        name
        address {
          address
          city
          state
          zip
          country
        }
        email
        phone
        dates {
          start
          end
        }
      }
      assets {
        assetId
        url
        type
        key
        originalName
      }
      date
      startDate
      frontendStepsStatus {
        isEnrollFieldCompleted
        isProgramSelectionCompleted
        isPropertyRightsCompleted
      }
      rawState
      envelope {
        envelopeId
        status
        envelopeUri
        createdDateTime
      }
      enrolledFields {
        id
        type
        properties {
          name
          farmId
          fieldId
          rawProperties
          area
        }
        geometry {
          type
          coordinates
        }
      }
    }
  }
`;
