import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

type Auth0ProviderProps = {
  children: React.ReactNode;
};

function Auth0ProviderWithHistory({
  children,
}: Auth0ProviderProps): JSX.Element {
  const params = new URLSearchParams(window.location.search);
  const cp =
    params.get('cp') === 'true' ||
    process.env['REACT_APP_CP_HOSTNAME'] === window.location.hostname;
  return (
    <Auth0Provider
      domain={'' + process.env['REACT_APP_AUTH0_DOMAIN']}
      clientId={'' + process.env['REACT_APP_AUTH0_CLIENT_ID']}
      audience={'' + process.env['REACT_APP_AUTH0_AUDIENCE']}
      scope={'' + process.env['REACT_APP_AUTH0_SCOPE']}
      redirectUri={`${window.location.origin}/redirect`}
      cp={cp}>
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
