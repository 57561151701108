import { Anchor } from '@agorocarbon/anchor';
import { Box } from '@agorocarbon/box';
import { Text } from '@agorocarbon/text';
import React from 'react';
import { toast } from 'react-toastify';
export const showToast = ({ title, message, type, toastId }: any) => {
  const SuccessContent = (
    <React.Fragment>
      <Box direction={'column'}>
        <Text size="medium" color="#191408">
          {title}
        </Text>
        <Text size="medium" weight="bold" color="#515564">
          {message}
        </Text>
      </Box>
    </React.Fragment>
  );

  const messageType: any = {
    success: '#07bc0c',
    warning: '#f1c40f',
    error: '#ff4a6b',
    info: '#3498db',
  };
  toast(SuccessContent, {
    toastId: toastId,
    style: {
      borderLeft: `5px solid ${messageType[type]}`,
    },
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: (props) => (
      <Anchor size="small" color="#1370BC" onClick={props.closeToast}>
        close
      </Anchor>
    ),
  });
};
