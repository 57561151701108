export const GET_OVERALL_DATAENTRY_COMPLETION_PERCENTAGE = `
  query getOverallGrowerFarmFieldsPercentage(
    $getOverallPercentageInput: GetOverallPercentageInput!
  ) {
    getOverallGrowerFarmFieldsPercentage(
      getOverallPercentageInput: $getOverallPercentageInput
    ) {
      growerId
      growerPercentage
      farms {
        farmId
        farmPercentage
        fields {
          fieldId
          fieldPercentage
        }
      }
    }
  }
`;
