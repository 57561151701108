/**
 * Event names should be found here
 */
export const RudderEventsTokens = {
  TRACK_SIGNED_IN: 'Signed In',
  TRACK_SIGNED_UP: 'Signed Up',
  TRACK_FIELDS_ADDED: 'Fields Added',
  TRACK_ELIGIBILITY_CHECKED: 'Eligibility Checked',
  TRACK_FIELDS_UPLOADED: 'Fields Uploaded',
  TRACK_FIELDS_UPLOAD_FAILED: 'Fields Upload Failed',
  TRACK_FIELD_MANUALLY_ADDED: 'Field manually added',
  TRACK_FIELD_MANUALLY_UPDATED: 'Field manually updated',
  TRACK_ELIGIBILITY_STARTED: 'Eligibility Started',
  TRACK_ELIGIBILITY_PASSED: 'Eligibility Passed',
  TRACK_ELIGIBILITY_FAILED_COMPLIANCE: 'Eligibility Failed Compliance',
  TRACK_ELIGIBILITY_FAILED: 'Eligibility Failed',
  TRACK_ENROLLMENT_STARTED: 'Enrollment started',
  TRACK_CONTRACT_SENT: 'Contract sent',
  TRACK_CONTRACT_SIGNED: 'Contract signed',
  TRACK_FIELD_DATA_COLLECTED: 'Field data saved',
  TRACK_TEMPLATE_DATA_COLLECTED: 'Template data saved',
  TEMPLATE_MULTIPLE_APPLIED: 'Template multiple applied',
  ADVISOR_ADDED: 'Advisor added',
  GROWER_INVITATION_SENT_BY_ADVISOR: 'Grower Invitation Sent By Advisor',
  MANUAL_ENROLLED: 'Manually Enrolled By Advisor',
};
