import {FC} from "react";
import {AgoroGeoJSON} from "./GeoJSON";
import {FieldFeature} from "../../graphql/types";

interface AgoroGeoJSONListProps {
  fields: FieldFeature[];
  fieldId?: string;
  showAreaLabel?: boolean
}

export const AgoroGeoJSONList: FC<AgoroGeoJSONListProps> = ({ fields, fieldId, showAreaLabel = false }: AgoroGeoJSONListProps) =>  {
  const geoJSONFeatures: Array<JSX.Element> = [];
  fields.forEach((field: FieldFeature) => {
    geoJSONFeatures.push(
      <AgoroGeoJSON
        key={`field_id_${field.properties?.fieldId ?? ''}`}
        showAreaLabel={showAreaLabel}
        fieldId={fieldId}
        field={field}
      />,
    );
  });

  return <>{geoJSONFeatures}</>;
}
