import { useClient } from 'urql';
import { GET_OVERALL_DATAENTRY_COMPLETION_PERCENTAGE } from '../graphql/queries/get.overallDataEntry.percentage.query';
import { useEffect, useRef, useState } from 'react';

const getPercentage = async (
  executeQuery: any,
  percentageRequestData: any[]
) => {
  const query: any = GET_OVERALL_DATAENTRY_COMPLETION_PERCENTAGE;
  const params: any = {
    getOverallPercentageInput: { inputParams: percentageRequestData },
  };

  const response = await executeQuery(
    query,
    { ...params },
    { requestPolicy: 'network-only' }
  ).toPromise();
  return { data: response.data, isFetching: false, error: null };
};

interface Request {
  growerPercentageList: any[];
  growerPercentageFetching: boolean;
  error: any;
}

export const useGetOverallDataEntryPercentage = (
  percentageRequestData: any[]
) => {
  const { query } = useClient();

  const [response, setResponse] = useState<Request>({
    growerPercentageList: [],
    growerPercentageFetching: true,
    error: null,
  });
  const growerPercentageList = useRef<any[]>([]);

  useEffect(() => {
    getPercentage(query, percentageRequestData)
      .then((res) => {
        const growerPercentages = res.data.getOverallGrowerFarmFieldsPercentage;
        const deltaPercentage = growerPercentages.filter(
          (growerPercentage: any) =>
            growerPercentageList.current.filter(
              (grPer) => grPer.growerId === growerPercentage.growerId
            ).length <= 0
        );

        growerPercentageList.current = [
          ...growerPercentageList.current,
          ...deltaPercentage,
        ];

        setResponse({
          growerPercentageList: growerPercentageList.current,
          growerPercentageFetching: false,
          error: res.error,
        });
      })
      .catch((err) => {
        setResponse({
          growerPercentageList: [],
          growerPercentageFetching: false,
          error: err,
        });
      });
  }, [percentageRequestData, query]);

  return response;
};

export default useGetOverallDataEntryPercentage;
