import {useForm} from "react-hook-form";


export const getLatitudeValue = (inputGeometryObject: any) => {
  return inputGeometryObject.type.toLowerCase() === 'polygon'
    ? inputGeometryObject.coordinates[0][0][0]
    : inputGeometryObject.coordinates[0][0][0][0];
};

export const getLongitudeValue = (inputGeometryObject: any) => {
  return inputGeometryObject.type.toLowerCase() === 'polygon'
    ? inputGeometryObject.coordinates[0][0][1]
    : inputGeometryObject.coordinates[0][0][0][1];
};

export const generateDataForm = (initialValue: any) => {
  const blankNewForm = {
    isApplicableCropInformation: true,
    isApplicableSyntheticNFertilizer: true,
    isApplicableManureCompost: true,
    isApplicableChemicalApplication: true,
    isApplicableTillage: true,
    isApplicableIrrigation: true,
    isApplicablePastureLandManagement: true,
    cropInformation: [{}],
    syntheticNFertilizer: [{}],
    manureCompost: [{}],
    chemicalApplication: [{}],
    tillage: [{}],
    irrigation: [{}],
    pastureLandManagement: [{}],
  };
  if (!initialValue) {
    return {
      defaultValues: blankNewForm,
    };
  }
  return {
    defaultValues: {
        ...initialValue,
        isApplicableCropInformation:
          initialValue.isApplicableCropInformation === undefined
            ? true
            : initialValue.isApplicableCropInformation,
        isApplicableSyntheticNFertilizer:
          initialValue.isApplicableSyntheticNFertilizer === undefined
            ? true
            : initialValue.isApplicableSyntheticNFertilizer,
        isApplicableManureCompost:
          initialValue.isApplicableManureCompost === undefined
            ? true
            : initialValue.isApplicableManureCompost,
        isApplicableChemicalApplication:
          initialValue.isApplicableChemicalApplication === undefined
            ? true
            : initialValue.isApplicableChemicalApplication,
        isApplicableTillage:
          initialValue.isApplicableTillage === undefined
            ? true
            : initialValue.isApplicableTillage,
        isApplicableIrrigation:
          initialValue.isApplicableIrrigation === undefined
            ? true
            : initialValue.isApplicableIrrigation,
        isApplicablePastureLandManagement:
          initialValue.isApplicablePastureLandManagement === undefined
            ? true
            : initialValue.isApplicablePastureLandManagement,
        pastureLandManagement:
          initialValue.pastureLandManagement.length > 0
            ? initialValue.pastureLandManagement
            : [{}],
      }
  };
}

export function setIsApplicableFormData(formData: any) {
  formData.isApplicableCropInformation = true;
  formData.isApplicableSyntheticNFertilizer = true;
  formData.isApplicableManureCompost = true;
  formData.isApplicableChemicalApplication = true;
  formData.isApplicableTillage = true;
  formData.isApplicableIrrigation = true;
  formData.isApplicablePastureLandManagement = true;
}

export function getFormattedDate(paramDate: string) {
  const date = new Date(paramDate);

  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '.' + day + '.' + year;
}

// validExtention = '.png, .gif, .jpeg, .jpg, .pdf';
export const fileExtentionValidate = (
    fileData: any,
    validExtention: any,
): boolean => {
  if (fileData) {
    const filePath = fileData.name;
    const getFileExt = filePath
        .substring(filePath.lastIndexOf('.') + 1)
        .toLowerCase();
    const position = validExtention.indexOf(getFileExt);
    if (position < 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export function makeInitials(name: string): string {
  //using old code to get initials
  const res = name.split(' ');
  const res1: string = res[0];
  const res2: string = res[1];

  const firstInitials = res1?.split('#').map(function (str) {
    return str ? str[0].toUpperCase() : '';
  });
  const lastInitials = res2?.split('#').map(function (str) {
    return str ? str[0].toUpperCase() : '';
  });
  return firstInitials.concat(lastInitials).join('');

  //keeping here for a possible upgrade (it does not handle numbers or names with more than 2 parts)
  // let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  // // @ts-ignore
  // let initials = [...name.matchAll(rgx)] || [];
  // initials = (
  //   (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  // ).toUpperCase();
  // return initials;
}
