import React, {FC} from "react";
import {List} from "grommet";
import {FieldsViewListItem} from "./FieldsViewListItem.component";
import useGetOverallDataEntryPercentage from "../../hooks/UseGetOverallDataEntryPercentage.hook";
import {useParams} from "react-router-dom";

export const FieldsViewList: FC<{
  fields: any[],
  showPercentage?: boolean,
  selectable?: boolean,
  selectedFields?: string[],
  onItemClick: (field: any) => void
}> = ({
  fields,
  showPercentage = true,
  selectable = false,
  selectedFields = [],
  onItemClick,
}) => {

  const {farmId, growerId} = useParams<{ farmId: string, growerId: string }>();
  const [percentageRequestData,] = React.useState<any[]>([
    {
      growerId,
      farmCount: 1,
      farmDetails: [{
        farmId: farmId,
        fieldCount: fields.length,
      }],
    },
  ]);
  const {growerPercentageList, growerPercentageFetching} = useGetOverallDataEntryPercentage(percentageRequestData);

  return (
    <List
      border={{color: 'transparent'}}
      style={{width: 'calc(80% + 41px + 1rem)', overflowX: 'auto', height: '55vh'}}
      pad={'0'}
      margin={{bottom: 'small'}}
      show={fields.length}
      children={(item: any) => {
        return <FieldsViewListItem
          field={item}
          showPercentage={showPercentage}
          selectable={selectable}
          selected={!!selectedFields?.find((fieldId) => item.properties.fieldId === fieldId)}
          fieldPercentageData={growerPercentageList}
          fieldPercentageFetching={growerPercentageFetching}
          onItemClick={(field) => onItemClick(field)}/>
        }
      }
      data={fields}/>
  );
}
