import {Box} from "grommet";
import validation_tick from "../../assets/svg/checkbox_check.svg";
import React, {useEffect, useState} from "react";
import {PadType} from "grommet/utils";

interface AgCheckBoxParams {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  id?: string;
  label?: React.ReactNode;
  fill?: boolean;
  pad?: PadType;
}

const AgCheckBox = ({checked, onChange, id = '', label, fill = false, pad}: AgCheckBoxParams) => {

  const [isChecked, setIsChecked] = useState<boolean>(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked])

  return (
    <Box
      pad={pad}
      align={'center'}
      gap={'small'}
      direction={'row'}
      width={fill ? '100%' : undefined}
      onClick={() => {
        if (isChecked !== checked) {
          if (onChange) {
            onChange(!isChecked)
          }
          setIsChecked(!isChecked)
        } else {
          setIsChecked(!checked);
          if (onChange) {
            onChange(!checked)
          }
        }
      }}>
      <Box
        hoverIndicator={{
          elevation: 'small'
        }}
        id={id}
        height={'24px'}
        width={'24px'}
        style={{border: `1px solid ${isChecked ? 'rgb(0, 79, 41)' : 'rgb(19, 112, 188)'}`, cursor: 'pointer', minWidth: '24px', minHeight: '24px' ,userSelect: 'none'}}
        background={isChecked ? 'rgb(238, 254, 241)' : 'white'}>
        {isChecked && <img
          style={{padding: '6px'}}
          src={validation_tick}
          alt={'AgoroLogo'}
          width="12px"
          height="12px"
        />}
      </Box>
      {label}
    </Box>
  );
}

export default AgCheckBox;
