import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet, matchPath, useLocation,
} from 'react-router-dom';
import React, {useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'grommet';
import { ToastContainer } from 'react-toastify';
// todo rewrite
import ESignatureProvider from '../contract/context/ESignatureProvider';
import GrowerInvite from './grower-invite/GrowerInvite.page';
import { Box } from '@agorocarbon/box';
import C3AdvisorOnboarding from '../components/advisor-onboarding/c3-advisor-onboarding';
import { C3OnboardingPersonalInfo } from '../components/onboarding-personal-info/c3-onboarding-personal-info';
import { C3GrowerOnboarding } from '../components/grower-onboarding/c3-grower-onboarding';
import ManageAdvisorsPage from './manage-advisors/ManageAdvisors.page';
import AuthGuard, {Roles} from "../guards/Auth.guard";
import NoContractsPage from "./no-contracts/NoContracts.page";
import RedirectPage from "./redirect/Redirect.page";
import LogoutPage from "./logout/Logout.page";
import DeleteFieldsPage from "./delete-fields/DeleteFields.page";

// components
const SidebarComponent = React.lazy(
  () => import('../components/sidebar/Sidebar.component')
);
const NavBarComponent = React.lazy(
  () => import('../components/navbar/NavBar.component')
);

// pages
const GrowersPage = React.lazy(() => import('./growers/Growers.page'));
const FarmsPage = React.lazy(() => import('./farms/Farms.page'));
const NotFoundPage = React.lazy(() => import('./not-found/NotFound.page'));
const PrivacyPolicyPage = React.lazy(
  () => import('./privacy-policy/PrivacyPolicy.page')
);
const TermsAndConditionsPage = React.lazy(
  () => import('./terms-and-conditions/TermsAndConditions.page')
);
const LoginPage = React.lazy(() => import('./login/Login.page'));
const SettingsPage = React.lazy(() => import('./settings/Settings.page'));
const AddGrowersPage = React.lazy(() => import('./add-growers/AddGrowers'));
const ManageFarmPage = React.lazy(
  () => import('./manage-farm/ManageFarm.page')
);
const AddFarmFieldOptionsPage = React.lazy(
  () => import('./add-farm-field-options/AddFarmFieldOptions.page')
);
const FarmFieldsViewPage = React.lazy(
  () => import('./farm-fields-view/FarmFieldsView.page')
);
const FarmFieldsEditPage = React.lazy(
    () => import("./farm-fields-edit/FarmFieldsEdit.page")
);
const UploadFieldBoundariesPage = React.lazy(
  () => import('./upload-farm-fields/UploadFieldBoundaries.page')
);
const DrawFarmFieldPage = React.lazy(
  () => import('./draw-farm-field/DrawFarmField.page')
);
const EditFarmFieldPage = React.lazy(
    () => import("./edit-farm-field/EditFarmField.page")
);
const EnrollFieldsPage = React.lazy(
  () => import('./enroll-fields/EnrollFields.page')
);
const FieldDataCollectionPage = React.lazy(
  () => import('./field-data/FieldDataCollection.page')
);
const FieldTemplatePage = React.lazy(
  () => import('./templates/Templates.page')
);
const EditTemplatePage = React.lazy(
  () => import('./templates/EditTemplate.page')
);
const CreateTemplatePage = React.lazy(
  () => import('./templates/CreateTemplate.page')
);
const ApplyTemplatePage = React.lazy(
  () => import('./templates/ApplyTemplate.page')
);
const EContractParentPage = React.lazy(
  () => import('../contract/screens/pages/EContractParentPage')
);
const EContractPracticeSelectionPage = React.lazy(
  () => import('../contract/screens/pages/EContractPracticeSelectionPage')
);
const EContractPropertyPage = React.lazy(
  () => import('../contract/screens/pages/EContractPropertyPage')
);
const EContractSuccess = React.lazy(
  () => import('../contract/screens/pages/EContractSuccess')
);
const EditEContract = React.lazy(
  () => import('./edit-contract/EditEContract.page')
);
const ReadOnlyEContract = React.lazy(
  () => import('./read-only-contract/ReadOnlyContract.page')
);
const ManageFarmBusiness = React.lazy(
    () =>import('./manage-farm-business/ManageFarmBusiness')
);
const FarmDataCollectionForm = React.lazy(
    () => import('./farm-data/FarmDataCollectionForm')
);

const ToastWrapper = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

const Layout = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  const routesWithoutSidebar = [
    '',
    '/',
    '/privacy-policy',
    '/terms-and-conditions',
    '/invites/:inviteId',
    '/onboarding-personal-information',
    '/farm-setup'
  ];
  const [sideBarExpanded, setSideBarExpanded] = useState(true);

  React.useEffect(() => {
    function handleResize() {
      setSideBarExpanded(window.outerWidth >= 1330);
    }
    window.addEventListener('resize', handleResize);
    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  });

  const sideBarToggle = () => {
    setSideBarExpanded(!sideBarExpanded);
  };

  if (isLoading) {
    return <AppRouterLoadingFallback />;
  }

  let hasMatch = false;
  for (const route of routesWithoutSidebar) {
    const matches = matchPath(route, location.pathname);
    if (matches) {
      hasMatch = true;
      break;
    }
  }

  if (
    !isAuthenticated ||
    hasMatch
  ) {
    return (
      <div>
        <ToastWrapper />
        <Outlet />
      </div>
    );
  }

  return (
    <div>
      <ToastWrapper />
      <div style={{ display: 'flex' }}>
        <SidebarComponent sideToggle={sideBarToggle} expanded={sideBarExpanded} />
        <div className="app-content" style={{ width: '100%' }}>
          <NavBarComponent />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const AppRouterLoadingFallback = () => (
  <Box direction={'row'} align={'center'} pad={'0.5rem 1rem'}>
    <div style={{ margin: '1rem auto' }}>
      <Spinner size={'large'} />
    </div>
  </Box>
);

const AppRouter = () => {
  return (
    <React.Suspense fallback={<AppRouterLoadingFallback />}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<LoginPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/invites/:inviteId" element={<GrowerInvite />} />
            {/* THIS ROUTES BELOW NEED AUTHENTICATION */}

            <Route path="/logout" element={<AuthGuard><LogoutPage /></AuthGuard>} />
            <Route path="/redirect" element={<AuthGuard><RedirectPage /></AuthGuard>} />
            <Route path="/growers" element={<AuthGuard><GrowersPage /></AuthGuard>} />
            <Route
              path="/growers/:growerId/add-new-advisor"
              element={<AuthGuard><C3AdvisorOnboarding /></AuthGuard>}
            />
            <Route
                path="/growers/:growerId/farms/:farmId/manage-farm-business"
                element={<ManageFarmBusiness />}
            />
            <Route path="/growers/:growerId/farms" element={<AuthGuard><FarmsPage /></AuthGuard>} />
            <Route
                path="/growers/:growerId/farms/:farmId/farm-data-collection"
                element={<AuthGuard><FarmDataCollectionForm /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/fields/view"
              element={<AuthGuard><FarmFieldsViewPage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/fields/delete"
              element={<AuthGuard><DeleteFieldsPage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/fields/upload"
              element={<AuthGuard><AddFarmFieldOptionsPage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/fields/edit"
              element={<AuthGuard><UploadFieldBoundariesPage /></AuthGuard>}
            />
            <Route
                path='/growers/:growerId/farms/:farmId/fields/edit-details'
                element={<AuthGuard><FarmFieldsEditPage/></AuthGuard>}/>
            <Route
              path="/growers/:growerId/farms/:farmId/field/:fieldId/field-data-collection"
              element={<AuthGuard><FieldDataCollectionPage /></AuthGuard>}
            />

            <Route
              path='/growers/:growerId/farms/:farmId/field/:fieldId/field-template'
              element={<AuthGuard><FieldTemplatePage /></AuthGuard>}
            />
            <Route
              path='/growers/:growerId/farms/:farmId/field/:fieldId/template/:templateId/view'
              element={<AuthGuard><EditTemplatePage /></AuthGuard>}
            />
            <Route
              path='/growers/:growerId/farms/:farmId/field/:fieldId/template/create'
              element={<AuthGuard><CreateTemplatePage /></AuthGuard>}
            />
            <Route
              path='/growers/:growerId/farms/:farmId/field/:fieldId/applyTemplate/:templateId'
              element={<AuthGuard><ApplyTemplatePage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/contracts/:contractId/fields"
              element={<AuthGuard><EnrollFieldsPage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/contracts/no-contracts"
              element={<AuthGuard><NoContractsPage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/field/add/options"
              element={<AuthGuard><AddFarmFieldOptionsPage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/field/add/drawing"
              element={<AuthGuard><DrawFarmFieldPage /></AuthGuard>}
            />
            <Route
                path='/growers/:growerId/farms/:farmId/field/:fieldId/edit/drawing'
                element={<AuthGuard><EditFarmFieldPage/></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/farms/:farmId/contracts/:contractId/summary"
              element={
                <AuthGuard>
                  <ESignatureProvider>
                    <EditEContract />
                  </ESignatureProvider>
                </AuthGuard>
              }
            />
            <Route
              path="/growers/:growerId/farms/:farmId/contracts/:contractId/summary/contract-preview"
              element={
                <AuthGuard>
                  <ESignatureProvider>
                    <ReadOnlyEContract />
                  </ESignatureProvider>
                </AuthGuard>
              }
            />
            <Route
              path="/growers/:growerId/farms/:farmId/contracts/:contractId/practice"
              element={
                <AuthGuard>
                  <ESignatureProvider>
                    <EContractPracticeSelectionPage />
                  </ESignatureProvider>
                </AuthGuard>
              }
            />
            <Route
              path="/growers/:growerId/farms/:farmId/contracts/:contractId/property"
              element={
                <AuthGuard>
                  <ESignatureProvider>
                    <EContractPropertyPage />
                  </ESignatureProvider>
                </AuthGuard>
              }
            />
            <Route
              path="/growers/:growerId/farms/:farmId/contracts/:contractId/summary/contract-success"
              element={
                <AuthGuard>
                  <ESignatureProvider>
                    <EContractSuccess />
                  </ESignatureProvider>
                </AuthGuard>
              }
            />
            <Route
              path="/growers/:growerId/farms/:farmId/edit"
              element={<AuthGuard><ManageFarmPage /></AuthGuard>}
            />
            <Route
              path="/growers/:growerId/add-farm"
              element={<AuthGuard><ManageFarmPage /></AuthGuard>}
            />
            <Route path="/growers/add-grower" element={<AuthGuard><AddGrowersPage /></AuthGuard>} />
            <Route
              path="/onboarding-personal-information"
              element={<AuthGuard><C3OnboardingPersonalInfo /></AuthGuard>}
            />
            <Route path="/farm-setup" element={<AuthGuard><ManageFarmPage /></AuthGuard>} />
            <Route path="/grower-farm-setup" element={<AuthGuard><C3GrowerOnboarding /></AuthGuard>} />
            <Route path="/settings" element={<AuthGuard><SettingsPage /></AuthGuard>} />
            <Route
              path="/growers/:growerId/manage-advisor"
              element={<ManageAdvisorsPage />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default AppRouter;
