import React, {FC, useEffect, useState} from "react";
import {Box} from "@agorocarbon/box";
import {FieldIcon} from "./FieldIcon.component";
import {Text} from "@agorocarbon/text";
import {Spinner, Tip} from "grommet";
import infoGreyIcon from "../../assets/svg/info_grey_icon.svg";
import {useNavigate, useParams} from "react-router-dom";
import {Checkbox} from "@agorocarbon/checkbox";
import { getFieldPercentage } from "../../contract/state/helpers";
import AgCheckBox from "../checkbox/AgCheckBox";

export const FieldsViewListItem: FC<{
  field: any,
  selectable?: boolean,
  selected?: boolean,
  disabled?: boolean,
  showPercentage?: boolean,
  fieldPercentageData: any[]
  fieldPercentageFetching: boolean
  onItemClick: (field: any) => void
}> = ({
  field,
  selectable = false,
  selected = false,
  disabled= false,
  showPercentage = true,
  fieldPercentageData,
  fieldPercentageFetching,
  onItemClick
}) => {

  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(selected);
  const {farmId, growerId} = useParams<{ farmId: string, growerId: string }>();

  useEffect(() => {
    setChecked(selected);
  }, [selected])

  return (
    <Box
      style={(disabled ? {opacity: 0.5, pointerEvents: 'none'} : {})}
      focusIndicator={false}
      hoverIndicator={true}
      direction={'row'}
      justify={'between'}
      pad={{vertical: 'medium', horizontal: 'small'}}
      onClick={() => {
        if (selectable) {
          setChecked(!checked)
        }
        field.selected = !checked;
        onItemClick(field)
      }}>
      <Box
        direction={'row'}>
        {selectable && <AgCheckBox checked={checked} onChange={(check) => setChecked(check)} pad={{right: 'small'}}/>}

        <FieldIcon
          feature={field}/>

        <Text
          size={'xlarge'}
          margin={{left: '16px'}}>
          {field.properties.name}
        </Text>
      </Box>

      {showPercentage && <Box
        onClick={() => navigate(`/growers/${growerId}/farms/${farmId}/field/${field.properties.fieldId}/field-data-collection`)}
        direction={'row'}
        align={'center'}
        pad={'0.5rem 1rem'}
        border={{color: '#FFE137'}}>
        {fieldPercentageFetching ? <Spinner /> :
          <>
            <Text
              size='large'
              color='text.secondary'>
              {fieldPercentageData && getFieldPercentage(
                fieldPercentageData,
                growerId ?? '',
                farmId ?? '',
                field.properties.fieldId
              )}
              %
            </Text>
            <Tip
              content={`You have filled out ${
                fieldPercentageData && getFieldPercentage(
                  fieldPercentageData,
                  growerId ?? '',
                  farmId ?? '',
                  field.properties.fieldId
                )
              }% of the required data entries`}>
              <img
                src={infoGreyIcon}
                alt='infoGreyIcon'
                height='16px'
                width='16px'
              />
            </Tip>
          </>}
      </Box>}
    </Box>
  );
};
