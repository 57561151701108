import gql from 'graphql-tag';

export const REMOVE_FIELDS = gql`
  mutation removeFields($ids: [String!]!, $farmId: String!) {
    removeFields(ids: $ids, farmId: $farmId) {
      message
      deletedCount
    }
  }
`;
