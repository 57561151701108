import {useNavigate} from "react-router-dom";
import './BreadCrumb.component.css';
import arrowRight from './../../assets/svg/breadcrumb_breaker.svg';
import React from "react";

interface Path {
  label: string,
  route: string,
  isActive?: boolean;
}

const BreadCrumbComponent = (props: { paths: Path[] }) => {
  const navigate = useNavigate();
  const paths = props.paths;

  paths[paths.length - 1].isActive = true;

  return <div>
    <div style={{display: 'flex'}}>
      {paths.map(i =>
        <div key={i.label} className={'breadcrumb-route'} style={{
          height: '32px', display: "flex", alignItems: 'center', justifyContent: 'center', padding: '8px 12px',
          fontSize: '0.8rem',
          color: i.isActive ? '' : 'rgb(19, 112, 188)',
          textDecoration: 'none',
          cursor: !i.isActive ? "pointer" : 'default'
        }} onClick={() => !i.isActive && navigate(i.route)}>
          {i.label}
          {!i.isActive && <img style={{marginLeft: '10px'}} src={arrowRight}/>}
        </div>
      )}
    </div>
  </div>
};

export default BreadCrumbComponent;
