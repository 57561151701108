import { Box } from '@agorocarbon/box';
import { useAuth0 } from '@auth0/auth0-react';
import { List } from 'grommet';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { PageTitle } from '../../components/page-title/PageTitle.component';
import { GET_ALL_ADVISORS_FOR_A_GROWER_QUERY } from '../../graphql/queries/get.all.advisors.for.grower.graphql';
import pendingIconSrc from '../../../src/assets/svg/pending.svg';
import tickIconSrc from '../../../src/assets/svg/tick.svg';
import { Text } from '@agorocarbon/text';

const ManageAdvisorsPage = () => {
  const { growerId } = useParams<{ growerId: string }>();
  const METADATA_KEY = `${process.env['REACT_APP_AUTH0_AUDIENCE']}user_metadata`;
  const user = useAuth0().user![METADATA_KEY];
  const [advisorResult] = useQuery({
    query: GET_ALL_ADVISORS_FOR_A_GROWER_QUERY,
    variables: { growerUserId: growerId },
    pause: !(growerId && growerId.length > 0),
  });
  if (advisorResult.fetching) {
    return null;
  }
  const currentGrower = advisorResult.data.listAllAdvisorsForAGrower.grower;
  const getPageTitle = (role: string) => {
    if (role === 'ADVISOR') {
      return t('advisor.viewAdvisorList.manageGrowersAddress', {
        growerFullName: `${currentGrower.firstName} ${currentGrower.lastName}`,
      });
    }
    return t('advisor.viewAdvisorList.view');
  };
  const getPageSubtitle = (role: string): any => {
    if (role === 'ADVISOR') {
      return t('advisor.viewAdvisorList.subHeading.manageAdvisors');
    }
    return (
      <div>
        {t('advisor.viewAdvisorList.subHeading.manageAdvisorForGrowerLine1')}
        <br />
        {t('advisor.viewAdvisorList.subHeading.manageAdvisorForGrowerLine2')}
      </div>
    );
  };

  const getStatusIconSrc = (status: string) => {
    if (status === 'Pending') {
      return pendingIconSrc;
    }
    return tickIconSrc;
  };

  const getStatusText = (advisor: any) => {
    if (advisor.userRole) {
      return t('advisor.viewAdvisorList.statusBodyText.accountCreated');
    }
    if (advisor.status === 'Pending') {
      return t('advisor.viewAdvisorList.statusBodyText.pending');
    }
    return t('advisor.viewAdvisorList.statusBodyText.accountCreated');
  };
  return (
    <Box
      direction={'column'}
      gap="xlarge"
      margin={{ horizontal: '4vw', vertical: '4vh' }}
    >
      <Box>
        <PageTitle
          title={getPageTitle(user.userRole)}
          subtitle={getPageSubtitle(user.userRole)}
          button={user.userRole === 'ADVISOR'}
          label={t('advisor.viewAdvisorList.addAdvisor')}
          route={`/growers/${growerId}/add-new-advisor`}
        />
      </Box>
      <List
        border="bottom"
        data={advisorResult.data.listAllAdvisorsForAGrower.advisors}
      >
        {(advisor: any) => {
          console.log('advisor', advisor);
          return (
            <Box
              direction={'row'}
              background={{ color: 'white' }}
              justify={'between'}
              align={'start'}
              height={{ min: '6em' }}
              style={{
                display: 'flex',
              }}
            >
              <Box
                width={{ min: '40px' }}
                direction={'row'}
                alignSelf={'center'}
                align={'center'}
              >
                <img
                  src={getStatusIconSrc(advisor.status)}
                  alt="icon"
                  width="20px"
                  height="20px"
                  style={{ paddingLeft: '30px', paddingRight: '30px' }}
                />
                <Box
                  style={{
                    lineHeight: '1rem',
                    textAlign: 'start',
                  }}
                  width={'64px'}
                  direction={'row'}
                  color="text.secondary"
                >
                  {getStatusText(advisor)}
                </Box>
                <Box direction="column" gap="xsmall">
                  <Box direction="row" gap="medium">
                    <Text size="xlarge" weight="bold">
                      {advisor.firstName} {advisor.lastName}
                    </Text>
                    <Text size="xlarge" style={{ opacity: '75%' }}>
                      {advisor.email}
                    </Text>
                  </Box>
                  <Box>
                    <Text size="xlarge">
                      {advisor.userRole || advisor.profession}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        }}
      </List>
    </Box>
  );
};

export default ManageAdvisorsPage;
