import React, {FC} from "react";
import {Box} from "@agorocarbon/box";
import {Heading} from "@agorocarbon/heading";
import {Text, TextProps} from "@agorocarbon/text";
import {useNavigate} from "react-router-dom";
import {Button} from '@agorocarbon/button';

export const PageTitle: FC<{
  title: string,
  subtitle?: string,
  button: boolean,
  label?: string,
  route?: string,
  isSmallButton?: boolean,
  subtitleSize?: TextProps['size'],
}> = ({
  title,
  subtitle,
  button,
  label,
  route,
  isSmallButton = false,
  subtitleSize = 'normal'
}) => {
  if (button && (!label || !route)) {
    throw new Error('label and route are mandatory if button is set');
  }

  const navigate = useNavigate();

  return (<Box
    direction={'row'}
    justify={'between'}
    margin={{bottom: '24px'}}>
    <Box direction={'column'} align={'start'}>
      <Heading size='primary' margin={{top: '0', bottom: '12px'}}>
        {title}
      </Heading>
      {subtitle && <Text size={subtitleSize}>
        {subtitle}
      </Text>}
    </Box>
    {button && <Button
      className={'page-title-button'}
      style={{padding: '0rem 2rem', background: 'white', border: '0.125rem solid rgb(252,225,92)', height: isSmallButton ? '2.3rem' : '3.45rem'}}
      onClick={() => {
        navigate(route ?? '');
      }}
      label={label ?? ''}
    />}
  </Box>);
};
