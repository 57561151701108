import { FormField } from '@agorocarbon/form-field';
import { Select } from '@agorocarbon/select';

//TODO: formValue is required for now for the select to have a default value as well as have the value change when the user changes it.  Make it optional in the future for uncontrolled forms
const AgSelectFormField = ({ field, formValue }: any) => {
  return (
    <FormField key={field.fieldId} {...(field as any)}>
      <Select
        value={formValue?.language || field.options[0]}
        name={field.name}
        options={field.options as any}
        labelKey="label"
        valueKey="value"
      ></Select>
    </FormField>
  );
};

export default AgSelectFormField;
