import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {useClient} from 'urql';
import {sha256} from 'js-sha256';

import {Box} from '@agorocarbon/box';
import {Button} from '@agorocarbon/button';
import {Heading} from '@agorocarbon/heading';
import {Text} from '@agorocarbon/text';
import {RadioButtonFormGroup, RadioOptionsProps} from '../AgRadioButtonFormGroup/AgRadioButtonFormGroup';
import {AgTextFormField as TextFormField} from "../../pages/add-growers/AgTextFormField";
import {Form} from '@agorocarbon/form';
import {Loader} from '@agorocarbon/loader';

import {CREATE_USER_MUTATION, CreateUserMutationParams} from './queries/create.user.mutation';
import onboardingIcon from './assets/onboarding_icon.svg'
import {showToast} from '../toast/toast';
import {Select} from "grommet";
import {FormField} from "../../pages/add-growers/FormField";
// todo reimplement rudderstack
// import { trackSignInSignUp } from '../../../../../apps/c3/src/providers/RudderStackProvider/rudder.analytics.service';
// import { RudderEventsTokens } from '../../../../../apps/c3/src/providers/RudderStackProvider/tokens/rudder.events.tokens';


export function C3OnboardingPersonalInfo() {
  const {t} = useTranslation();
  const {user, getAccessTokenSilently} = useAuth0();
  const {mutation} = useClient();
  const navigate = useNavigate();
  const location = useLocation();
  const userMetaData = user && user['https://api.pa.agoro.comuser_metadata'];


  const [isLoading, setIsLoading] = useState(false);
  const [isMount, setIsMount] = useState(true);

  const fields = {
    firstName: {
      fieldId: 'ob__input__first__name',
      name: 'firstName',
      minLength: 2,
      maxLength: 30,
      label: t('common.label.firstName'),
      required: true,
      placeholder: t('common.placeholder.firstName'),
      value: '',
    },
    lastName: {
      fieldId: 'ob__input__last__name',
      name: 'lastName',
      minLength: 2,
      maxLength: 30,
      label: t('common.label.lastName'),
      required: true,
      placeholder: t('common.placeholder.lastName'),
    },
    phoneNumber: {
      fieldId: 'ob__input__phone__number',
      name: 'phoneNumber',
      minLength: 2,
      maxLength: 30,
      regex: new RegExp(/^[()+0-9\b-]+$/),
      label: t('common.label.phoneNumber'),
      required: true,
      placeholder: t('common.placeholder.phoneNumber')
    },
    language: {
      name: 'language',
      fieldId: 'language-input',
      type: 'dropdown',
      label: t('common.label.language'),
      required: true,
      options: [
        {label: 'English', value: 'en-US'},
        {label: 'Espana', value: 'es-US'},
      ],
      value: {label: 'English', value: 'en-US'},
      variant: '',
    }
  }
  const options: RadioOptionsProps[] = [
    {
      id: 'advisor-radio',
      value: 'ADVISOR',
      title: t('onboarding.onboardingPersonalInformation.radioButton.advisor'),
      description: t('onboarding.onboardingPersonalInformation.radioButton.desc.advisor'),
      disabled: false,
      titleSize: '16px'
    },
    {
      id: 'grower-radio',
      value: 'GROWER',
      title: t('onboarding.onboardingPersonalInformation.radioButton.grower'),
      description: t('onboarding.onboardingPersonalInformation.radioButton.desc.grower'),
      disabled: false,
      titleSize: '16px'
    }
  ]

  const [language, setLanguage] = useState(fields.language.value.value);
  const [userRole, setUserRole] = useState<string>(String(options[0].value));

  function successToast() {
    showToast({
      title: t('onboarding.onboardingPersonalInformation.toast.title.infoUpdated'),
      message: t('onboarding.onboardingPersonalInformation.toast.content.infoUpdatedSubTitle'),
      type: 'success',
      toastId: 'user-created-toast-id'
    })
  }

  function errorToast(message: string = t('common.unexpectedError')) {
    showToast({
      title: t('common.errorTitle'),
      message,
      type: 'error',
      toastId: 'user-created-error-toast-id'
    })
  }

  // function rudderstackEvent(userMetaData: any){
  //   //  Analytics for Signed Up
  //   return  trackSignInSignUp(
  //     userMetaData,
  //     location.pathname,
  //     RudderEventsTokens.TRACK_SIGNED_UP,
  //   );
  // }

  async function submit(formValues: CreateUserMutationParams) {
    setIsLoading(true);
    let webId = '';
    const email = user?.email || '';
    if (window.localStorage.getItem('webId')) {
      webId = '' + window.localStorage.getItem('webId');
    } else {
      webId = sha256(email);
    }
    const data = {
      ...formValues,
      webId,
      email,
      companyId: '' // todo: verify companyId is required but in was never set
    }
    return mutation(CREATE_USER_MUTATION, {...data}).toPromise()
      .then(async (createUserResult) => {
        if (createUserResult.data) {
          const resultData = createUserResult?.data?.createUser;
          const userId = resultData?.userId;

          await getAccessTokenSilently({ignoreCache: true});

          successToast();
          setIsLoading(false);
          const destination = formValues.userRole === 'ADVISOR' ?
            '/grower-farm-setup' : '/farm-setup';
          navigate(destination)
        } else {
          setIsLoading(false);
          errorToast(createUserResult.error?.message.split('[GraphQL] ')[1]);
        }
      }).catch((err) => {
        setIsLoading(false);
        errorToast(err?.message)
      })

  }

  return (<>
    {isLoading && <Loader/>}
    {isMount && <Box width='100%' align='center'>
      <Box style={{width: '40vw', maxWidth: '30rem'}}>
        <Form
          validate={'submit'}
          onSubmit={({value}) => submit(value)}
        >
          <img src={onboardingIcon} alt={'onboardingIcon'}/>

          <Heading
            alignSelf='start'
            textAlign='start'
            size={'16px'}
            margin={{bottom: '1.5rem'}}
            level='3'>
            {t('onboarding.onboardingPersonalInformation.heading.createYourAccount')}
          </Heading>

          <Text
            weight={'bold'}>
            {t('onboarding.onboardingPersonalInformation.subHeading.enterPersonalInfo')}
          </Text>

          <Box
            margin={{top: '1.5rem'}}>
            <TextFormField
              {...fields.firstName}/>
            <TextFormField
              {...fields.lastName}/>
            <TextFormField
              {...fields.phoneNumber}/>
          </Box>

          <Box width={'small'} margin={{bottom: '1.5rem'}}>
            <FormField name="language" label={fields.language.label}>
              <Select
                labelKey="label"
                name={fields.language.name}
                valueKey={{key: 'value', reduce: true}}
                options={fields.language.options}
                value={language}
                onChange={({value: nextValue}) => setLanguage(nextValue)}
              />
            </FormField>
          </Box>

          <Text
            weight={'bold'}>
            {t('common.label.role')}
          </Text>

          <RadioButtonFormGroup
            margin={{vertical: '1.5rem'}}
            name="userRole"
            value={userRole}
            onChange={(event) => setUserRole(event.target.value)}
            radioOptions={options}/>
          <Box>
            <Button type="submit" alignSelf='stretch' primary label={t('common.continue')}/>
          </Box>
        </Form>
      </Box>
    </Box>}
  </>);
}
