import { useQuery } from 'urql';
import { GET_GROWER_BY_ID_QUERY } from '../graphql/queries/get.grower.by.id.query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMemoGrower = (suspense = true) => {
  const context = useMemo(() => ({ suspense }), [suspense]);
  const { growerId = '' } = useParams<{growerId: string}>();
  const [result] = useQuery({
    query: GET_GROWER_BY_ID_QUERY,
    requestPolicy: 'cache-and-network',
    variables: { userId: growerId },
    pause: !growerId,
    context,
  });

  const { data } = result;

  return (
    data?.user || {
      userId: '',
      auth0Id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      invites: null,
      userRole: '',
      companyId: '',
    }
  );
};
